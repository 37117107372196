.tenant-success {
    padding: 195px 0 165px;
    overflow: hidden;
    &__inner {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
    &__content {
        width: 53%;
        max-width: 453px;
        margin-left: auto;
        margin-right: 45px;
    }
    &__title {
        font-weight: 300;
        font-size: 40px;
        line-height: 142.52%;
        text-transform: uppercase;
        margin-bottom: 30px;
        max-width: 330px;
    }
    &__text {
        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }

    }
    &__link {
        margin-top: 20px;
    }
    &__pic {
        position: relative;
        width: 44%;
        max-width: 540px;
        img {
            position: absolute;
            left: 0;
            bottom: -165px;
        }
    }
}

@media screen and (max-width: 1024px) { 
    .tenant-success {
        padding: 140px 0;
    }
}

@media screen and (max-width: 768px) { 
    .tenant-success {
        padding: 100px 0;
        &__pic {
            img {
                bottom: -100px;
            }
        }
    }
}

@media screen and (max-width: 660px) { 
    .tenant-success {
        padding: 44px 0 0;
        &__inner {
            flex-direction: column;
        }
        &__content {
            width: 100%;
            max-width: none;
            margin: 0 0 40px;
        }
        &__title {
            font-weight: 300;
            font-size: 22px;
            line-height: 120%;
            color: #3B3C40;
            text-transform: none;
            margin-bottom: 13px;
        }
        &__pic {
            width: 100%;
            margin-bottom: -90px;
            max-width: none;
            img {
                position: static;
                width: 270px;
                margin: 0 auto;
            }
        }
    }
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 72px;
    z-index: 10;
    &__btns {
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 10;
    }
    &__burger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 72px;
        padding: 29px 25px 25px 23px;
        background: #74BBBF;
        opacity: 0.5;
        width: fit-content;
        outline: none;
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: .3s ease;
        &:hover {
            opacity: 1;
        }
        span {
            width: 24px;
            height: 3px;
            background: #2A2A2D;
            margin-bottom: 4px;
            transform-origin: 50% 50%;
            transition: .3s ease;
            &:last-child {
                margin-bottom: 0;
            }
            &:nth-child(2) {
                width: 20px;
            }
        }
        &.active {
            span {
                width: 24px;
                &:first-child {
                    transform: rotate(45deg) translate(5px, 5px);
                }
                &:nth-child(2) {
                    width: 0;
                }
                &:last-child {
                    transform: rotate(-45deg) translate(5px, -5px);
                }
            }
        }
    }
    &__btn {
        transform-origin: top left;
        width: 313px;
        height: 72px;
        transform: rotate(270deg) translateX(-313px);
    }
    &__menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: #1C232D;
        opacity: 0;
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    &__logo {
        display: none;
    }
}

.consult-btn {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: inherit;
    text-align: center;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    color: #FFFFFF;
    padding: 23px 30px 26px 39px;
    background: #C36355;
}

.header-menu {
    padding: 55px 0;
    &__logo {
        transition: .3s linear;
        margin-bottom: 160px;
        .icon-svg {
            width: 176px;
            height: 45.13px;
            fill: #FFFFFF;
        }
    }
    &__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 38px;
        font-weight: 300;
        font-size: 28px;
        line-height: 135%;
        transition: .3s linear;
        opacity: 0;
        transform: translateY(30px);
    }
    &__nav {
        grid-column: 1 / 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__nav-col {
        flex: 1 0 0;
        width: 100%;
        margin-bottom: 38px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__item {
        margin-bottom: 38px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__link {
        color: #74BBBF;
        &:hover {
            color: #B9DCDE;
        }
        &.active {
            position: relative;
            color: #FFFFFF;
            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                border-bottom: 1px solid #FFFFFF;
            }
        }
    }
    &__contacts {
        color: #74BBBF;
    }
    &__language {
        position: absolute;
        top: 21px;
        right: 32px;
    }
    &__btn {
        display: none;
    }
    &.active {
        .header-menu__content {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.header-contacts {
    &__title {
        margin-bottom: 38px;
    }
    &__list {
        display: flex;
        flex-direction: column;
    }
    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 38px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__icon {
        width: 31px;
        height: 31px;
        display: flex;
        justify-content: center;
        margin-right: 18px;
        flex-shrink: 0;
        .icon-svg {
            width: 100%;
            height: auto;
            fill: #74BBBF;
        }
    }
    &__link {
        white-space: nowrap;
        color: #74BBBF;
        &:hover {
            color: #B9DCDE;
        }
    }
    &__socials {
        margin-top: 70px;
    }
}

.language {
    display: flex;
    &__link {
        color: #74BBBF;
        margin-right: 22px;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            color: #FFFFFF;
            text-decoration: underline;
        }
    }
}

.socials {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 265px;
    column-gap: 20px;
    row-gap: 20px;
    &__link {
        width: 54px;
        height: 54px;
        transition: .3s ease;
        &:last-child {
            margin-right: 0;
        }
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: #74BBBF;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}

.menu-dropdown {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    &__head {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-right: 21px;
        width: fit-content;
        cursor: pointer;
        &.active {
            .header-menu {
                &__link {
                    color: #FFFFFF;
                }
            }
            .icon-svg {
                stroke: #FFFFFF;
                transform: scaleY(-1);
            }
        }
    }
    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        flex-shrink: 0;
        width: 11px;
        height: 37px;
        cursor: pointer;
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: none;
            stroke: #74BBBF;
            transition: .3s ease;
        }
        &:hover {
            .icon-svg {
                stroke: #B9DCDE;
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: .3s ease;
    }
    &__link {
        font-size: 22px;
        line-height: 135.02%;
        color: #74BBBF;
        margin-bottom: 13px;
        width: fit-content;
        &.active {
            position: relative;
            color: #FFFFFF;
            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                border-bottom: 1px solid #FFFFFF;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            color: #B9DCDE;
        }
    }
}

.menu-dropdown__head.active ~ .menu-dropdown__list {
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 1;
    max-height: none;
}

@media screen and (max-width: 1400px) {
    .header-menu {
        &__logo {
            margin-bottom: 90px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header-menu {
        padding: 20px 0;
        &__logo {
            margin-bottom: 60px;
        }
        &__content {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 30px;
            font-size: 22px;
        }
        &__nav {
            row-gap: 0;
        }
        &__contacts {
            grid-column: 1 / 3;
        }
    }
    .header-contacts {
        &__title {
            margin-bottom: 20px;
        }
        &__item {
            margin-bottom: 15px;
        }
        &__icon {
            width: 25px;
            height: 26px;
            margin-right: 10px;
        }
    }
    .menu-dropdown {
        &__icon {
            height: 29px;
            &:hover {
                .icon-svg {
                    stroke: #FFFFFF;
                }
            }
        }
        &__link {
            font-size: 13px;
            line-height: 135.02%;
            color: #FFFFFF;
        }
    }
}

@media screen and (max-width: 660px) {
    .header {
        &__btns {
            .header__btn {
                display: none;
            }
        }
        &__burger {
            padding: 15px 13px 17px 13px;
            opacity: 1;
        }
        &__logo {
            position: fixed;
            top: 0;
            right: 0;
            width: calc(100% - 50px);
            height: 49px;
            display: block;
            padding: 11px 0 0 16px;
            background: #2A2A2D;
            .icon-svg {
                width: 110px;
                height: 28.2px;
                object-fit: contain;
                fill: #FFFFFF;
            }
        }
    }
    .header-menu {
        background: #2A2A2D;
        padding: 11px 0 70px;
        &__logo {
            padding-left: 50px;
            margin-bottom: 40px;
            .icon-svg {
                width: 110px;
                height: 28.2px;
            }
        }
        &__language {
            top: 18px;
            right: 16px;
        }
        &__nav {
            display: flex;
            flex-direction: column;
            grid-template-columns: auto;
            row-gap: 0;
        }
        &__nav-col {
            width: 100%;
            margin-bottom: 6px;
        }
        &__item {
            margin-bottom: 6px;
        }
        &__link {
            color: #F5F5F5;
            font-weight: 300;
            font-size: 15px;
            line-height: 135.02%;
        }
        &__btn {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 142.52%;
            padding: 19px 40px 18px;
            height: auto;
        }
    }
    .header-contacts {
        padding-top: 17px;
        border-top: 1px solid #414144;
        color: #F5F5F5;
        font-weight: 300;
        font-size: 15px;
        line-height: 135.02%;
        &__link {
            color: #F5F5F5;
        }
        &__icon {
            width: 20px;
            height: 20px;
            .icon-svg {
                fill: #F5F5F5;
            }
        }
        &__title {
            margin-bottom: 15px;
        }
        &__socials {
            margin-top: 30px;
        }
    }
    .socials {
        max-width: 160px;
        &__link {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            .icon-svg {
                fill: #FFFFFF;
            }
        }
    }
    .language {
        font-size: 16px;
        line-height: 135.02%;
        &__link {
            margin-right: 8px;
            color: #6C6E75;
            &.active {
                text-decoration: none;
                font-weight: 600;
                color: #FFFFFF;
            }
        }
    }
    .menu-dropdown {
        &__head {
            width: calc(100% + 36px);
            transform: translateX(-16px);
            padding-left: 16px;
            padding-right: 37px;
            padding-top: 6px;
            padding-bottom: 6px;
            transition: .3s ease;
            &.active {
                background: #2E2E31;
                ~ .menu-dropdown__list {
                    background: #2E2E31;
                    padding-bottom: 10px;
                }
            }
        }
        &__icon {
            top: 3px;
            right: 10px;
            height: 20px;
            .icon-svg {
                stroke: #FFFFFF;

            }
        }
        &__list {
            width: calc(100% + 36px);
            transform: translateX(-16px);
            padding-left: 16px;
            padding-right: 16px;
        }
        &__link {
            padding-left: 10px;
            &.active {
                &::before {
                    left: 10px;
                    width: calc(100% - 10px);
                }
            }
        }
    }
    .menu-item-1 {
        order: 1;
    }
    .menu-item-2 {
        order: 2;
    }
    .menu-item-3 {
        order: 3;
    }
    .menu-item-4 {
        order: 4;
    }
    .menu-item-5 {
        order: 5;
    }
    .menu-item-6 {
        order: 6;
    }
    .menu-item-7 {
        order: 7;
    }
}

.page-list__loyalty{
    .page-list__item{
        margin-bottom: 16px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.left-pic__labloyalty_analytics{
    padding: 260px 0;
    @media(max-width: 1400px){
        padding: 100px 0 160px;
    }
    @media(max-width: 1024px){
        padding: 50px 0;
    }
}

.labloyalty-slider{
    padding-bottom: 80px;
    @media(max-width: 1400px){
        padding: 100px 0 80px;
    }
    @media(max-width: 1024px){
        padding: 50px 0;
    }
    @media(max-width: 600px){
        padding: 0;
    }
    .section-title_small{
        margin-bottom: 50px;
    }
    .post-slide{
        grid-template-columns: calc(40% - 15px) calc(60% - 15px);
        display: grid;
        gap: 30px;
        @media(max-width: 1024px){
            grid-template-columns: 1fr;
        }
    }
    &__slide_title{
        margin: 20px 0 30px;
        font-size: 32px;
        font-weight: 300;
        line-height: normal;
        color: #3B3C40;
        @media(max-width: 600px){
            margin: 0 0 30px;
            font-size: 20px;
        }
    }
    .posts-swiper__next, .posts-swiper__prev{
        background-color: #F0F8FA;
    }
    .posts-swiper__btns{
        position: relative;
        left: calc(40% + 15px);
        @media(max-width: 1024px){
            left: 0;
        }
    }
}

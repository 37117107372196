.tabs-swipers {
    padding: 85px 0 100px;
    &__inner {
        position: relative;
    }
    &__title {
        margin-bottom: 40px;
        padding-right: 140px;
    }
    &__nav {
        border-bottom: 1px solid #D5D5D8;
        margin-bottom: 60px;
        .swiper-slide {
            width: fit-content;
            flex-grow: 1;
        }
    }
    &__btns {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        .swiper {
            &__btn {
                background: #F0F8FA;
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 15px 10px;
        text-align: center;
        border-radius: 5px 5px 0px 0px;
        background: transparent;
        width: 100%;
        cursor: pointer;
        transition: .3s ease;
        flex-grow: 1;
        height: initial;
        font-size: 14px;
        line-height: 140.52%;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background: #D5D5D5;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        &:hover {
            background: #74BBBF;
            color: #FFF;
        }
        &.active {
            &:hover {
                opacity: 1;
            }
            background: #74BBBF;
            color: #FFF;
            &::after {
                display: none;
            }
        }
    }
    &-digtal-controls{
        .tabs-slide{
            &__swiper{
                width: 50%;
                margin-left: 0;
                @media (max-width: 1024px){
                    max-width: 100%;
                    width: 100%;
                }
                @media (max-width: 600px){
                    padding-bottom: 0;
                }
            }
            &__content{
                width: 50%;
                max-width: 540px;
                @media (max-width: 1400px){
                    padding-left: 40px;
                }
                @media (max-width: 1024px){
                    max-width: 100%;
                    width: 100%;
                    padding-left: 0;
                    margin-top: 30px;
                }
            }
        }
        .section-title_small{
            @media (max-width: 600px){
                padding-top: 0;
                margin-top: 0;
                padding-right: 0;
            }
        }
    }
}

.tabs-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__content {
        width: 39%;
        max-width: 455px;
    }
    &__title {
        font-size: 32px;
        line-height: 120%;
        color: #3B3C40;
        margin-bottom: 35px;
    }
    &__list {
        .page-list {
            &__item {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__pic {
        flex-grow: 1;
        padding: 18px;
        background: #F0F8FA;
        border-radius: 10px;
        margin-bottom: 18px;
        img {
            width: 100%;
            height: auto;
        }
        &_tenantlab{
            img{
                aspect-ratio: 2 / 1;
                object-fit: cover;
                object-position: top;
            }
        }
    }
    &__swiper {
        position: relative;
        width: 56%;
        max-width: 665px;
        margin-right: 0;
    }
    &__description {
        display: flex;
        align-items: center;
        padding-left: 146px;
        font-size: 16px;
        line-height: 140.52%;
        color: #74BBBF;
        min-height: 49px;
        &_tenantlab{

            padding: 14px 0 32px 0;

            @media(max-width: 1024px){
                font-size: 24px;
            }
            @media(max-width: 600px){
                font-size: 18px;
                padding: 0;
            }
        }
    }
    &__btns {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        z-index: 2;
        background: #FFFFFF;
        &_tenantlab{
            position: relative;
        }
    }
    &__prev,
    &__next {
        background: #F0F8FA;
    }
    &-full-width{
        flex-direction: column;
        align-items: flex-start;
        .tabs-slide{
            &__content{
                width: 100%;
                max-width: 100%;
            }
            &__list{
                display: flex;
                flex-flow: row wrap;
                column-gap: 30px;
                row-gap: 20px;
                .page-list{
                    &__item{
                        width: calc(50% - 15px);
                        margin-bottom: 0;
                    }
                    &__icon{
                        margin-right: 15px;
                    }
                    &__col{
                        width: calc(50% - 15px);
                        margin-bottom: 0;
                        @media(max-width: 768px){
                            width: 100%;
                        }
                        .page-list__item{
                            width: 100%;
                            margin-bottom: 15px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            &__swiper{
                width: 100%;
                max-width: 100%;
                margin-top: 30px;
                .tabs-slide__pic{
                    img{
                        aspect-ratio: 2 / 1;
                        object-fit: cover;
                        object-position: top;
                    }
                }
            }
        }
    }
}

.tabs {
    &__content {
        overflow: hidden;
    }
    &__pane {
        display: none;
        opacity: 0;
        transform: translateY(100%);
        transition: .5s ease;
        &.fade {
            display: block;
        }
        &.active {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media screen and (max-width: 1024px) {
    .tabs-swipers {
        padding: 50px 0;
    }
    .tabs-slide {
        flex-direction: column;
        align-items: flex-start;
        &__content {
            width: 100%;
            max-width: none;
            margin-bottom: 26px;
        }
        &__swiper {
            width: 100%;
            max-width: none;
        }
        &__pic {
            margin-left: 0;
            width: 100%;
            margin: 0 auto 18px;
            padding: 7px;
        }
    }
}

@media screen and (max-width: 660px) {
    .tabs-swiper {
        &__tabs {
            margin-bottom: 45px;
            border: none;
        }
        &__tab {
            font-size: 12px;
            padding: 8px 16px;
            border-radius: 5px;
            background: #F0F8FA;
            &::after {
                display: none;
            }
        }
    }
    .tabs-swipers {
        &__nav {
            border-bottom: none;
            margin-bottom: 25px;
        }
        &__link {
            padding: 8px 16px;
            font-size: 11px;
            line-height: 140.52%;
            border-radius: 5px;
            background: #F0F8FA;
        }
    }
    .tabs-slide {
        &__swiper {
            padding-bottom: 40px;
        }
        &__title {
            font-weight: 500;
            font-size: 20px;
            color: #6C6E75;
            margin-bottom: 23px;
        }
        &__description {
            padding-left: 0;
            min-height: auto;
        }
    }
}

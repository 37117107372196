.press {
    padding: 50px 0 55px;
}

.press-list {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 30px 30px 0px 0px;
    z-index: 3;
    padding: 57px 51px 0;
    margin: -200px -51px 0;
    &__item {
        margin-bottom: 50px;
    }
    &__btn {
        align-self: center;
        transition: .3s ease;
        &:hover {
            background: #74BBBF;
            border-color: #74BBBF;
            color: #FFFFFF;
        }
    }
}

.press-card {
    display: flex;
    padding-bottom: 50px;
    border-bottom: 1px solid #DEDEDE;
    &__pic {
        width: 42%;
        max-width: 475px;
        height: auto;
        overflow: hidden;
        border-radius: 8px;
        margin-right: 40px;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__link {
        color: #2A2A2D;
        &:hover {
            color: #74BBBF;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 58%;
    }
    &__date {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #6C6E75;
        margin-bottom: 11px;
    }
    &__title {
        font-weight: 300;
        font-size: 28px;
        line-height: 120%;
        color: #2A2A2D;
        margin-bottom: 17px;
    }
    &__text {
        position: relative;
        font-size: 18px;
        line-height: 150%;
        color: #6C6E75;
        p {
            margin-bottom: 7px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .press-card__gradient-link {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(177.25deg, rgba(255, 255, 255, 0) 2.29%, #FFFFFF 79.95%);
        }
    }
}

@media screen and (max-width: 1024px) {
    .press-card {
        flex-direction: column;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &__pic {
            max-width: none;
            width: 100%;
            height: 300px;
            margin-right: 0;
            margin-bottom: 20px;
        }
        &__content {
            width: 100%;
        }
    }
}
@media screen and (max-width: 920px) {
    .press-list {
        margin: 0;
        padding: 0;
    }
}
@media screen and (max-width: 660px) {
    .press {
        padding: 22px 0;

    }
    .press-list {
        &__item {
            margin-bottom: 30px;
        }
        &__btn {
            padding: 19px 0 18px;
            width: 100%;
            background: #F0F8FA;
            font-weight: 500;
            font-size: 14px;
            line-height: 142.52%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            border: none;
            color: #6C6E75;
        }
    }
    .press-card {
        padding-bottom: 10px;
        &__pic {
            height: 150px;
        }
        &__date {
            margin-bottom: 8px;
        }
        &__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
        }
        &__text {
            font-size: 16px;
            line-height: 140%;
        }
        &__link {
            color: #6C6E75;
        }
    }

}
.info-swipers {
    display: flex;
    flex-direction: column;
}

.info-swiper {
    padding: 75px 0;
    background: #F0F8FA;
    overflow: hidden;
    &:first-child {
        padding-top: 140px;
    }
    &:nth-child(2n) {
        background: #FFFFFF;
        .info-swiper {
            &__inner {
                flex-direction: row-reverse;
            }
            &__swiper {
                margin-left: 95px;
                margin-right: auto;
            }
            &__content {
                margin-right: 0;
                margin-left: 0;
            }
            &__prev {
                background: #F0F8FA;
            }
            &__next {
                background: #F0F8FA;
            }
        }
    }
    &__inner {
        display: flex;
        align-items: center;
    }
    &__swiper {
        position: relative;
        margin-right: 95px;
        margin-left: 0;
        max-width: 440px;
        .swiper-slide {
            display: flex;
            justify-content: center;
            img {
                transition: .5s ease;
                opacity: 0;
                transform: translateY(50%);
                max-width: 260px;
            }
            &.swiper-slide-active {
                img {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 60px;
            left: 0;
            width: 90%;
            height: 45%;
            border: 35px solid #D4EDEE;
            border-right: none;
            border-bottom: none;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 60px;
            right: 0;
            width: 90%;
            height: 45%;
            border: 35px solid #D4EDEE;
            border-left: none;
            border-top: none;
        }
    }
    &__content {
        flex-grow: 1;
        max-width: 440px;
    }
    &__title {
        font-size: 32px;
        line-height: 140.52%;
        color: #74BBBF;
        margin-bottom: 25px;
    }
    &__btns {
        display: flex;
        margin-bottom: 40px;
    }
    &__prev {
        width: 54px;
        height: 49px;
        background: #FFFFFF;
        &.swiper-button-disabled {
            opacity: 0.8;
            pointer-events: none;
        }
    }
    &__next {
        width: 54px;
        height: 49px;
        background: #FFFFFF;
        &.swiper-button-disabled {
            opacity: 0.8;
            pointer-events: none;
        }
    }
    &__list {
        .page-list {
            &__item {
                cursor: pointer;
                font-weight: 300;
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 15px;
                color: #3B3C40;
                transition: font-weight .3s ease;
                &:last-child {
                    margin-bottom: 0;
                }
                &.active {
                    .page-list__text {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .info-swiper {
        &__inner {
            flex-direction: column;
        }
        &__swiper {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: none;
            margin-bottom: 20px;
        }
        &__content {
            display: flex;
            flex-direction: column;
            max-width: none;
            width: 100%;
        }
        &__btns {
            order: 3;
            margin-bottom: 0;
            margin-top: 30px;
        }
        &:nth-child(2n) {
            .info-swiper {
                &__inner {
                    flex-direction: column;
                }
                &__swiper {
                    margin-left: auto;
                }
            }
        }
    }
}


@media screen and (max-width: 660px) {
    .info-swiper {
        padding: 40px 0 30px;
        &:first-child {
            padding: 40px 0 30px;
        }
        &__title {
            font-weight: 300;
            font-size: 22px;
            line-height: 120%;
            color: #3B3C40;
            margin-bottom: 25px;
        }
        &__list {
            .page-list {
                &__item {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 140%;
                }
            }
        }
        &__swiper {
            .swiper-slide {
                img {
                    max-width: 190px;
                }
            }
        }
    }
}
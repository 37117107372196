.left-pic-bg {
    background: #F0F8FA;
    padding: 70px 0;
    &__inner {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 155px 80px 120px 120px;
        background: #FFFFFF;
        border-radius: 30px;
        overflow: hidden;
    }
    &__pic {
        position: absolute;
        left: 120px;
        bottom: 0;
        width: 40%;
        max-width: 338px;
        img {
            width: 100%;
            height: auto;
        }
    }
    &__content {
        width: 52%;
        max-width: 520px;
    }
    &__title {
        margin-bottom: 30px;
    }
    &__text {
        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .left-pic-bg {
        &__inner {
            padding: 155px 40px 120px;
        }
        &__pic {
            left: 40px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .left-pic-bg {
        &__inner {
            padding: 0;
            background: transparent;
            flex-direction: column-reverse;
            align-items: flex-start;
            border-radius: 0;
        }
        &__content {
            width: 100%;
            max-width: none;
            margin-bottom: 27px;
        }
        &__pic {
            position: relative;
            left: auto;
            width: 100%;
            max-width: none;
            height: 588px;
            background: #FFFFFF;
            border-radius: 30px;
            overflow: hidden;
            img {
                position: absolute;
                width: calc(100% - 100px);
                bottom: 0;
                max-width: 400px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .left-pic-bg {
        padding: 35px 0 25px;
        &__title {
            margin-bottom: 10px;
        }
        &__pic {
            height: 288px;
            img {
                max-width: 200px;
            }
        }
    }
}
.cases-swiper {
    position: relative;
    padding: 95px 0;
    &__title {
        margin-bottom: 32px;
    }
    &__btns {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        left: 0;
        z-index: 10;
    }
    &__prev {
        background: #F0F8FA;
    }
    &__next {
        background: #F0F8FA;
    }
}

.case-slide {
    position: relative;
    padding-bottom: 70px;
    overflow: hidden;
    &__pic {
        position: relative;
        width: 100%;
        height: 580px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(184, 184, 184, 0.22) 0%, rgba(126, 126, 126, 0.56) 100%);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__content {
        position: absolute;
        bottom: 0;
        right: -100%;
        display: flex;
        flex-direction: column;
        padding: 40px 50px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
        max-width: 550px;
        transition: .3s ease;
    }
    &__title {
        font-weight: 300;
        font-size: 32px;
        line-height: 39px;
        color: #2A2A2D;
        margin-bottom: 23px;
        a {
            color: inherit;
            &:hover {
                color: #74bbbf;
            }
        }
    }
    &__text {
        margin-bottom: 30px;
        p {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__link {
        color: #2A2A2D;
    }
    &.swiper-slide-active {
        .case-slide__content {
            right: -1px;
        }
    }
}

@media screen and (max-width: 1024px) { 
    .cases-swiper {
        padding: 50px 0;
        .swiper-wrapepr {
            align-items: stretch;
        }
    }
    .case-slide {
        display: flex;
        flex-direction: column;
        height: initial;
        &__pic {
            height: 340px;
        }
        &__content {
            position: static;
            flex-grow: 1;
            height: auto;
            max-width: none;
            width: 100%;
            padding: 40px;
        }

    }
}

@media screen and (max-width: 768px) {
    .cases-swiper {
        &__swiper {
            opacity: 1 !important;
            transform: translate3d(0, 0, 0) !important;
        }
    }
    .case-slide {
        &__content {
            padding: 40px 20px;
        }
    }
}

@media screen and (max-width: 660px) {
    .case-slide {
        padding-bottom: 40px;
        &__content {
            padding: 20px;
        }
        &__pic {
            height: 200px;
        }
        &__title {
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 12px;
        }
        &__text {
            margin-bottom: 15px;
        }
    }
}
.intro {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 115px 0 115px;
    color: #E0E0E0;
    z-index: 1;
    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .video-bg {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            &__mobile {
                display: none;
            }
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: -1;
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }
        }

    }
    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(11, 11, 11, 0.74);
        z-index: 0;
    }
    &__logo {
        position: absolute;
        top: 55px;
        left: auto;
        .icon-svg {
            width: 176px;
            height: 45.13px;
            fill: #FFFFFF;
        }
        .logo-png{
            height: 45.13px;
            @media(max-width: 600px){
                display: none;
            }
        }
    }
    &__title {
        font-weight: 300;
        font-size: 35px;
        line-height: 142.52%;
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    &__swiper {
        .swiper-wrapper {
            align-items: stretch;
        }
        .swiper-slide {
            height: auto;
        }
    }
    &__swiper-pagination {
        bottom: 0 !important;
        .swiper-pagination-bullet {
            background: #FFFFFF;
            opacity: 0.4;
            transition: .3s ease;
            &.swiper-pagination-bullet-active {
                border-radius: 6px;
                width: 22px;
                background: #FFFFFF;
                opacity: 0.8;
            }
        }
    }
    &__mobile-btn {
        display: none;
    }
    &_absolute-pic {
        overflow: hidden;
        padding: 100px 0;
        .container {
            min-height: calc(100vh - 230px);
        }
        .intro {
            &__inner {
                position: relative;
                min-height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &__logo {
                top: -45px;
            }
            &__title {
                font-weight: 300;
                font-size: 60px;
                line-height: 142.52%;
                max-width: 490px;
                margin-bottom: 35px;
            }
            &__text {
                max-width: 365px;
            }
            &__pic {
                position: absolute;
                bottom: -115px;
                right: 0;
                width: 56%;
                max-width: 650px;
                img {
                    width: auto;
                    height: auto;
                    object-fit: contain;
                    margin-left: auto;
                }
            }
        }
    }
    &_small {
        display: flex;
        flex-direction: column;
        padding: 55px 0;
        min-height: auto;
        .intro__title {
            padding-top: 170px;
            margin-bottom: 0;
            font-size: 60px;
            line-height: 142.52%;
        }
    }
    &_line {
        min-height: auto;
        padding: 35px 0 36px;
        .intro__logo {
            top: 17px;
            .icon-svg {
                width: 137px;
                height: 35.13px;
            }
        }
    }
    &_about {
        .intro {
            &__title {
                font-size: 60px;
                line-height: 142.52%;
                margin-bottom: 25px;
            }
            &__text {
                max-width: 770px;
            }
        }
    }
    &_card {
        overflow: hidden;
        padding-bottom: 0;
        min-height: auto;
        .intro {
            &__inner {
                display: flex;
                flex-direction: column;
                min-height: calc(100vh - 115px);
            }
            &__content {
                width: 100%;
                max-width: none;
                margin-top: auto;
            }
            &__content-text {
                display: flex;
                justify-content: space-between;
                margin-bottom: 120px;
            }
            &__title {
                font-size: 60px;
                line-height: 142.52%;
                margin-bottom: 0;
                max-width: 440px;
            }
            &__text {
                font-weight: 300;
                font-size: 24px;
                line-height: 140%;
                max-width: 294px;
            }
            &__leaping-text {
                font-weight: 300;
                font-size: 18px;
                line-height: 140%;
                max-width: 365px;
                span {
                    color: #74BBBF;
                }
            }
            &__right-pic {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                display: none;
                img {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: none;
                    width: 838px;
                    height: auto;
                }
            }
            &__gift-cards {
                position: relative;
                display: flex;
                align-items: flex-end;
                width: 100%;
                overflow: visible;
                .swiper-wrapper {
                    align-items: flex-end;
                }
            }
        }
        .gift-cards-swiper {
            &__btns {
                display: none;
            }
        }
        .intro-overlay {
            background: linear-gradient(134.24deg, #0C0C0C 0%, #1C1C1C 97.37%);
            opacity: 0.9;
        }
    }
    &_mars {
        overflow: hidden;
        .intro {
            &__content {
                display: grid;
                grid-template-columns: 500px 1fr;
                gap: 10px;
            }
            &__content-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &__title {
                font-size: 60px;
                line-height: 142.52%;
                margin-bottom: 18px;
                max-width: 300px;
            }
            &__text {
                font-weight: 300;
                font-size: 18px;
                line-height: 140%;
                max-width: 370px;
                margin-bottom: 20px;
            }
            &__leaping-text {
                font-weight: 300;
                font-size: 18px;
                line-height: 140%;
                max-width: 490px;
                span {
                    color: #74BBBF;
                }
            }
            &__right-pic {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                img {
                    max-width: none;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    &_digital-control {
        .intro {
            &__title {
                font-weight: 300;
                font-size: 60px;
                line-height: 142.52%;
                margin-bottom: 35px;
                max-width: 850px;
            }
            &__text {
                max-width: 607px;
                margin-bottom: 45px;
                color: #E0E0E0;
            }
            &__btn {
                display: none;
                padding: 23px 55px;
                color: #FFFFFF;
            }
        }
    }
    &_labadv{
        .intro {
            &__title {
                font-weight: 300;
                font-size: 60px;
                line-height: 142.52%;
                margin-bottom: 35px;
                max-width: 850px;
            }
            &__text {
                max-width: 607px;
                margin-bottom: 45px;
                color: #E0E0E0;
            }
            &__btn {
                display: none;
                padding: 23px 55px;
                color: #FFFFFF;
            }
        }
    }
    &_error {
        .intro {
            &__title {
                font-size: 60px;
                line-height: 142.52%;
                margin-bottom: 25px;
                text-transform: none;
            }
            &__text {
                font-weight: 300;
                margin-bottom: 35px;
            }
            &__btn {
                padding: 23px 53px;
                color: #FFFFFF;
            }
        }
    }
    &__form-btn{
        padding: 16px 44px;
        background: #74BBBF;
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        z-index: 2;
        @media (max-width: 660px) {
            text-align: center;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 142.52%;
            padding: 19px 30px 18px;
        }
    }
}

@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 6 + s;
        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
};

.intro-gift {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 0 65px 50px;
    backdrop-filter: blur(20px);
    @include set-delay(3);
    &__pic {
        margin-bottom: 20px;
        img {
            width: 100%;
            max-width: 265px;
        }
    }
    &__text {
        font-weight: 300;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
    }
    &:nth-child(1) {
        background: linear-gradient(57.42deg, rgba(82, 193, 200, 0.9) 8.76%, rgba(65, 136, 140, 0.9) 83.89%);
        box-shadow: 60px 60px 100px rgba(20, 40, 41, 0.2);
        .intro-gift {
            &__pic {
                margin-top: -40px;
            }
        }
    }
    &:nth-child(2) {
        position: relative;
        padding: 0 25px 50px;
        background: linear-gradient(194.39deg, rgba(180, 185, 127, 0.9) 13%, rgba(141, 145, 80, 0.9) 83.15%);
        box-shadow: 60px 60px 100px rgba(20, 40, 41, 0.2);
        .intro-gift {
            &__pic {
                margin-top: -80px;
                max-width: 187px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: -18px;
            left: -18px;
            width: 26.64px;
            height: 90.52px;
            border: 1px solid #878C9A;
            border-right: none;
            border-bottom: none;
        }
        &::after {
            content: "";
            position: absolute;
            top: -18px;
            right: -18px;
            width: 26.64px;
            height: 90.52px;
            border: 1px solid #878C9A;
            border-left: none;
            border-bottom: none;
        }
    }
    &:nth-child(3) {
        background: linear-gradient(287.72deg, rgba(168, 61, 34, 0.9) 16.53%, rgba(232, 138, 48, 0.9) 70.52%);
        box-shadow: 60px 60px 100px rgba(20, 40, 41, 0.2);
        .intro-gift {
            &__pic {
                margin-top: -40px;
            }
        }
    }
}

.intro-list {
    display: flex;
    justify-content: space-between;
    &__item {
        max-width: 29%;
    }
}

.intro-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &__title {
        display: flex;
        align-items: flex-start;
        font-weight: 500;
        font-size: 30px;
        line-height: 135.02%;
        max-width: 300px;
        color: #74BBBF;
        padding-bottom: 10px;
        border-bottom: 1px solid #FFFFFF;
        margin-bottom: 30px;
    }
    &__text {
        font-weight: 300;
        line-height: 140%;
        margin-bottom: 30px;
    }
}

.leaping-text {
    padding: 55px 0 70px;
    display: none;
    &__text {
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        max-width: 870px;
        span {
            color: #74BBBF;
        }
    }
}

@media screen and (max-width: 1400px) {
    .intro {
        &_card {
            .intro {
                &__content-text {
                    flex-direction: column;
                    margin-bottom: 120px;
                }
                &__text {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 140%;
                    max-width: none;
                    margin-bottom: 16px;
                }
                &__leaping-text {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 140%;
                    max-width: none;
                }
            }
            .intro-gift {
                &:nth-child(2) {
                    .intro-gift {
                        &__pic {
                            max-width: 100px;
                        }
                    }
                }
                padding: 0 18px 25px;
                &__pic {
                    max-width: 140px;
                }
                &__text {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
        }
        &_digital-control {
            .intro {
                &__btn {
                    padding: 15px 55px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .intro {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 90px 0;
        &__logo {
            top: 20px;
        }
        &__swiper {
            padding-bottom: 30px;
        }
        &_absolute-pic {
            justify-content: flex-end;
            align-items: stretch;
            padding-bottom: 0;
            .intro {
                &__inner {
                    position: static;
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;
                    height: 100%;
                }
                &__logo {
                    top: 20px;
                }
                &__text {
                    margin-bottom: 20px;
                }
                &__pic {
                    position: static;
                    align-self: center;
                    width: 100%;
                    max-width: 400px;
                    margin-bottom: -100px;
                }
            }
        }
        &_line {
            padding: 35px 0 36px;
        }
        &_mars {
            .intro {
                &__content {
                    grid-template-columns: 1fr;
                }
                &__right-pic {
                    img {
                        margin: 0 auto;
                        width: 80%;
                    }
                }
            }
        }
        &_card {
            padding-bottom: 0;
            .intro {
                &__leaping-text {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .intro {
        min-height: auto;
        padding: 150px 0 90px;
        &_card {
            padding-bottom: 0;
            .intro {
                &__inner {
                    min-height: auto;
                }
                &__gift-cards {
                    padding-top: 40px;
                    overflow: hidden;
                    .swiper-wrapper {
                        align-items: stretch;
                    }
                }
                &__content-text {
                    margin-bottom: 35px;
                }
            }
            .intro-gift {
                height: initial;
                flex-direction: row;
                align-items: flex-start;
                &__pic {
                    flex-grow: 1;
                    flex-basis: 0;
                    max-width: none;
                    margin-bottom: 0;
                    margin-right: 25px;
                    transition: .3s ease;
                    transition-delay: .5s;
                    transform: translateY(100%);
                    opacity: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        max-width: none;
                        max-height: 220px;
                        object-fit: contain;
                    }
                }
                &__text {
                    flex-grow: 1;
                    flex-basis: 0;
                    max-width: none;
                    text-align: left;
                    padding-top: 20px;
                    transition-delay: 1s;
                    transform: translateX(100%);
                    transition: .3s ease;
                    opacity: 0;
                }
                &:nth-child(2) {
                    &::before,
                    &::after {
                        display: none;
                    }
                    .intro-gift {
                        &__pic {
                            margin-top: -40px;
                            max-width: none;
                        }
                    }
                }
                &.swiper-slide-active {
                    .intro-gift {
                        &__pic {
                            transform: translateY(0);
                            opacity: 1;
                        }
                        &__text {
                            transform: translateX(0);
                            opacity: 1;
                        }
                    }
                }
            }
            .gift-cards-swiper {
                &__btns {
                    position: absolute;
                    left: calc(50% + 12.5px);
                    bottom: 55px;
                    display: flex;
                    align-items: center;
                    z-index: 2;
                }
                &__prev,
                &__next {
                    border: 1px solid #EBEBEB;
                    .icon-svg {
                        stroke: #EBEBEB;
                    }
                    &.swiper-button-disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
        &_mars {
            padding-bottom: 0;
            .intro {
                &__right-pic {
                    img {
                        width: 100%;
                    }
                }
                &__text {
                    margin-bottom: 25px;
                }
            }
        }
        &_line {
            padding: 0;
            height: 71px;
        }
    }
}

@media screen and (max-width: 660px) {
    .intro {
        min-height: 90vh;
        align-items: stretch;
        padding: 65px 0;
        &__inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        &-bg {
            .video-bg {
                &__mobile {
                    display: block;
                }
                &__desktop {
                    display: none;
                }
            }
        }
        &__swiper {
            max-width: 100%;
        }
        &__logo {
            top: 13px;
            padding-left: 50px;
            margin-bottom: 25px;
            .icon-svg {
                width: 110px;
                height: 28.2px;
            }
        }
        &__title {
            font-weight: 300 !important;
            font-size: 32px !important;
            line-height: 120% !important;
            margin-bottom: 30px !important;
        }
        &__text {
            font-size: 15px;
            line-height: 1.2;
            font-weight: 300;
        }
        &__mobile-btn {
            display: block;
        }
        &__mobile-btn {
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 14px;
            line-height: 142.52%;
            padding: 19px 30px 18px;
            height: auto;
            z-index: 2;
        }
        &_absolute-pic {
            padding-bottom: 0;
            .intro {
                &__logo {
                    top: 13px;
                }
            }
        }
        &_small {
            padding-bottom: 25px;
            min-height: auto;
            .intro__title {
                padding-top: 55px;
                font-size: 28px;
                line-height: 120%;
                text-transform: none;
            }
        }
        &_line {
            padding: 0;
            min-height: auto;
            height: 49px;
            .intro__logo {
                top: 13px;
                left: auto;
                .icon-svg {
                    width: 86px;
                    height: 22.05px;
                }
            }
        }
        &_card {
            justify-content: flex-end;
            padding-bottom: 0;
            .intro {
                &__logo {
                    margin-bottom: 40px;
                }
                &__title {
                    font-size: 28px;
                    line-height: 120%;
                    text-transform: none;
                    margin-bottom: 10px;
                }
                &__text {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 140%;
                    margin-bottom: 10px;
                }
                &__leaping-text {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 140%;
                }
                &__content-text {
                    margin-bottom: 0;
                }
                &__mobile-btn {
                    order: 2;
                }
            }
            .intro-gift {
                flex-direction: column;
                &__pic {
                    display: flex;
                    align-items: flex-end;
                    margin-right: 0;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &__text {
                    width: 100%;
                    padding-right: 90px;
                    flex-grow: 0;
                }
            }
            .gift-cards-swiper {
                &__btns {
                    left: auto;
                    right: 18px;
                    bottom: 25px;
                }
            }
        }
        &_mars {
            padding-bottom: 0;
            justify-content: flex-end;
            .intro {
                &__title {
                    font-size: 28px;
                    line-height: 120%;
                    text-transform: none;
                }
                &__text {
                    font-size: 16px;
                    line-height: 140%;
                    max-width: 343px;
                }
                &__right-pic {
                    img {
                        margin: 0 auto;
                        width: 100%;
                    }
                }
                &__leaping-text {
                    font-size: 16px;
                }
                &__mobile-btn {
                    order: 2;
                    margin-bottom: 30px;
                    margin-top: 20px;
                }
            }
        }
        &_digital-control {
            .intro {
                &__title {
                    font-weight: 300;
                    font-size: 32px;
                    line-height: 120%;
                    text-transform: none;
                    margin-bottom: 10px;
                }
                &__text {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 140%;
                    margin-bottom: 15px;
                }
                &__btn {
                    display: block;
                    width: 100%;
                    padding: 19px 18px 18px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 142.52%;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    background: #C36355;
                    border-color: #C36355;
                }
            }
        }
        &_about {
            .intro__title {
                font-weight: 300;
                font-size: 32px;
                line-height: 120%;
                text-transform: none;
            }
        }
        &_error {
            .intro {
                &__btn {
                    width: 100%;
                    padding: 19px 18px 18px;
                }
            }
        }
    }
    .intro-card {
        &__title {
            font-weight: 500;
            font-size: 22px;
            line-height: 135.02%;
            margin-bottom: 10px;
        }
        &__text {
            margin-bottom: 15px;
        }
    }
    .leaping-text {
        padding: 20px 0 30px;
        &__text {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
        }
    }
}

.select {
    position: relative;
    width: 100%;
    z-index: auto;
    &__top {
        position: relative;
        background-color: transparent;
        padding: 20px;
        font-size: 16px;
        line-height: 20px;
        padding-right: 50px;
        color: #B9DCDE;
        border: 1px solid #B9DCDE;
        transition: .2s ease;
        cursor: pointer;
        &::after {
            content: "";
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 8px;
            background: url(../../images/svg/arrow-bottom.svg) no-repeat center;
            background-size: contain;
        }
    }
    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        opacity: 0;
        z-index: 2;
        background-color: #fff;
        transition: .2s ease;
        max-height: 200px;
        overflow: auto;
    }
    &__item-input {
        display: none;
    }
    &__item-label {
        display: block;
        width: 100%;
        font-size: 1rem;
        line-height: 1;
        color: #333;
        cursor: pointer;
        a {
            display: block;
            width: 100%;
            padding: 10px 15px;
            &:hover {
                background: #B9DCDE;
            }
        }
        span {
            display: block;
            width: 100%;
            padding: 10px 15px;
            &:hover {
                background: #B9DCDE;
            }
        }
    }
    &.active {
        .select {
            &__top {
                border-color: #fff;
                color: #fff;
                &::after {
                    transform: translateY(-50%) scaleY(-1);
                }
            }
            &__dropdown {
                height: auto;
                opacity: 1;
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
            }
        }
    }
    &.selected {
        .select {
            &__top-label {
                display: none;
            }
        }
    }
}

.select__item-input:checked ~ .select__item-label {
    a {
        background: #b1dcd785;
    }
    span {
        background: #b1dcd785;
    }
}

@media screen and (max-width: 660px) {
    .select {
        &__top {
            border-color: #fff;
            color: #fff;
        }
    }
}

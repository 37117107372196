.relative-posts {
    padding: 70px 0;
    background: #F0F8FA;
    &__title {
        font-weight: 300;
        font-size: 32px;
        line-height: 39px;
        color: #2A2A2D;
        margin-bottom: 33px;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }
}

.relative-post {
    display: flex;
    flex-direction: column;
    &__pic {
        width: 100%;
        height: 235px;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 24px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__date {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #6C6E75;
        margin-bottom: 6px;
    }
    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #2A2A2D;
        margin-bottom: 11px;
    }
    &__link {
        transition: .3s ease;
        color: #2A2A2D;
        &:hover {
            color: #74BBBF;
        }
    }
}

@media screen and (max-width: 1024px) {
    .relative-posts {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 768px) {
    .relative-posts {
        padding: 44px 0 10px;
        &__list {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .relative-post {
        padding-bottom: 10px;
        border-bottom: 1px solid #DEDEDE;
        &__pic {
            height: 150px;
            margin-bottom: 20px;
        }
    }
}
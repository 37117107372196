.form-group {
    position: relative;
    transition: .3s ease;
    &__input {
        width: 100%;
        font-family: inherit;
        font-size: 16px;
        line-height: 20px;
        color: #3B3C40;
        padding: 20px;
        outline: none;
        border: 1px solid rgba(255, 255, 255, 0.88);
        background: rgba(255, 255, 255, 0.88);
        &:focus {
            border-color: rgba(255, 255, 255, 0.88);
        }
    }
    &__label {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 16px;
        line-height: 20px;
        pointer-events: none;
        color: #3B3C40;
        transition: .3s ease;
    }
    .error-message {
        position: absolute;
        top: 100%;
        left: 0;
        font-size: 13px;
        line-height: 20px;
        color: #C04432;
        display: none;
    }
    &.error {
        margin-bottom: 20px;
        .form-group {
            &__input {
                border-color: #C04432;
            }
            &__label {
                color: #C04432;
            }
        }
        .error-message {
            display: block;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #FFFFFF;
  -webkit-text-fill-color: #FFFFFF;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  background: transparent;
  transition: background-color 5000s ease-in-out 0s;
}

textarea.form-group__input {
    resize: none;
}

.form-group__input:focus ~ .form-group__label,
.form-group__input:not(:placeholder-shown) ~ .form-group__label {
    transform: translateY(-15px);
    color: #3B3C40;
    font-size: 10px;
}

.form-submit {
    &__text {
        font-size: 14px;
        line-height: 18px;
        color: #B9DCDE;
        max-width: 330px;
        a {
            color: #FFFFFF;
            &:hover {
                color: #B9DCDE;
            }
        }
    }
    &__btn {
        background: rgba(255, 255, 255, 0.88);
        color: #36303B;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        outline: none;
        border: none;
        box-shadow: none;
        padding: 16px 44px;
        cursor: pointer;
        transition: .3s ease;
        &:disabled{
            opacity: .5;
        }
    }
}

form {
    &.filled {
        .form-submit {
            &__btn {
                background: #FFFFFF;
                color: #36303B;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .form-group {
        &__input {
            border-color: #FFFFFF;
            color: #3B3C40;
        }
        &__label {
            color: #3B3C40;
        }
    }
    .form-submit {
        &__btn {
            width: 100%;
        }
        &__text {
            margin-bottom: 18px;
        }
    }
}

.b2b-crm {
    background: #F0F8FA;
    padding: 85px 0 80px;
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__content {
        width: 48%;
        max-width: 560px;
    }
    &__title {
        margin-bottom: 20px;
    }
    &__text {
        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h3 {
            font-weight: 300;
            font-size: 22px;
            line-height: 150%;
        }
    }
    &__pic {
        width: 49%;
        max-width: 568px;
        img {
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 1024px) { 
    .b2b-crm {
        &__inner {
            flex-direction: column;
        }
        &__content {
            width: 100%;
            max-width: none;
            margin-bottom: 20px;
        }
        &__pic {
            width: 100%;
        }
    }
}

@media screen and (max-width: 660px) { 
    .b2b-crm {
        overflow: hidden;
        padding: 35px 0 20px;
        &__title {
            margin-bottom: 13px;
        }
        &__text {
            p {
                margin-bottom: 13px;
            }
            h3 {
                font-weight: normal;
                font-size: 18px;
                line-height: 128%;
                margin-bottom: 6px;
                color: #3B3C40;
            }
        }
        &__pic {
            img {
                width: 400.59px;
                max-width: none;
            }
        }
    }
}   
.scroll-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    padding: 20px 10px;
    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .down {
        transition: .3s ease;
    }
    &:hover {
        .down {
            transform: translateY(3px);
        }
    }
}

@media (max-width: 1023px) {
    .scroll-down {
        display: none;
    }
}
.posts-swiper {
    padding: 45px 0 80px;
    background: #F0F8FA;
    &__inner {
        position: relative;
    }
    &__link {
        position: absolute;
        right: 0;
        top: 26px;
    }
    &__title {
        margin-bottom: 65px;
    }
    &__btns {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        z-index: 10;
    }
    &__prev {
        background-color: #FFFFFF;
    }
    &__next {
        background-color: #FFFFFF;
    }
}

.post-slide {
    display: flex;
    justify-content: space-between;
    &__pic {
        width: 49%;
        max-width: 570px;
        overflow: hidden;
        border-radius: 15px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        width: 47%;
        max-width: 540px;
    }
    &__title {
        font-weight: 300;
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 30px;
        color: #2A2A2D;
        a {
            color: inherit;
            &:hover {
                color: #74BBBF;
            }
        }
    }
    &__text {
        margin-bottom: 40px;
        p {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__date {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        margin-top: auto;
    }
}

@media screen and (max-width: 1024px) { 
    .posts-swiper {
        padding: 50px 0;
        &__swiper {
            padding-bottom: 50px;
        }
        &__link {
            left: 0;
            top: auto;
            right: auto;
            bottom: 0;
        }
    }
    .post-slide {
        flex-direction: column;
        &__pic {
            width: 100%;
            max-width: none;
            height: auto;
            overflow: hidden;
            margin-bottom: 20px;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__content {
            width: 100%;
            max-width: none;
        }
        &__date {
            order: 1;
            margin: 0 0 12px;
        }
        &__title {
            order: 2;
        }
        &__text {
            order: 2;
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 1024px) { 
    .posts-swiper {
        padding: 40px 0;
        &__title {
            margin-bottom: 20px;
        }
    }
    .post-slide {
        &__title {
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 12px;
        }
    }
}
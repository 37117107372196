.fullwidth-slider{
    padding: 80px 0;
    @media(max-width: 600px){
        padding: 35px 0 20px;
    }
    .section-title_small{
        margin-bottom: 48px;
        @media(max-width: 600px){
            margin-bottom: 13px;
        }
    }
}

.card-advantages {
    padding: 120px 0;
    background: #F0F8FA;
    overflow: hidden;

    &__title {
        margin-bottom: 30px;
    }

    &__text {
        font-size: 18px;
        line-height: 150%;
        color: #6C6E75;
        max-width: 550px;
    }
}


.card-list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .intro__form-btn{
        text-align: center;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 28px 50px;
        background: #DEEDF0;
        border-radius: 56.5px;
        margin-bottom: 123px;

        &:last-child {
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }

        &.no-after{
            margin-bottom: 44px;
            &::after{
                display: none;
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -80px;
            width: 30px;
            height: 30px;
            background: url('../../images/svg/plus.svg') no-repeat center;
            background-size: contain;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        width: 45px;
        height: 50px;
        flex-shrink: 0;
        margin-right: 15px;

        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: #74BBBF;
        }

        img {
            width: auto;
            height: auto;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }
}

@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 10 + s;

        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
}

;

.card-infographics {
    display: grid;
    grid-template-columns: 390px 1fr;

    &__frame {
        &.aos-animate {
            .info-bar {
                transform: scaleY(1);
                @include set-delay(12);
            }
        }
    }

    &__frame-wrapper {
        position: relative;
        width: calc(100% + 140px);
    }

    &__pic {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        position: absolute;
        width: 69%;
        top: 18%;
        left: calc(15% - 3px);

        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.info-bar {
    transform-origin: 50% 90%;
    transform: scaleY(0);
    transition: .5s ease;
}

@media screen and (max-width: 1024px) {
    .card-advantages {
        padding: 50px 0;

        &__text {
            margin-bottom: 20px;
        }
    }

    .card-infographics {
        grid-template-columns: 1fr;

        &__frame-wrapper {
            width: 100%;
        }
    }

    .card-list {
        align-items: center;

        &__item {
            width: auto;
            padding: 20px 80px;
        }
    }
}

@media screen and (max-width: 660px) {
    .card-advantages {
        padding: 24px 0;

        &__title {
            margin-bottom: 10px;
        }

        &__text {
            margin-bottom: 15px;
        }
    }

    .card-list {
        &__item {
            width: 100%;
            padding: 10px 26px 10px;
            margin-bottom: 37px;
            &::after {
                bottom: -30px;
                width: 20px;
                height: 20px;
            }
        }

        &__icon {
            width: 30px;
            height: 30px;
        }

        &__text {
            font-weight: 300;
            font-size: 14px;
            line-height: 140%;
        }
    }
}

.float-list {
    padding: 90px 0 0;
    &__title {
        margin-bottom: 45px;
    }
    &__list {
        margin-bottom: -70px;
    }
}

@media screen and (max-width: 768px) {
    .float-list {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}

@media screen and (max-width: 660px) {
    .float-list {
        padding: 35px 0 20px;
        &__list {
           margin-bottom: 0;
        }
    }
}
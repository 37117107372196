.back-link {
    display: flex;
    align-items: center;
    color: #74BBBF;
    transition: .3s ease;
    &__icon {
        flex-shrink: 0;
        width: 15px;
        height: 10px;
        margin-right: 5px;
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            stroke: #74BBBF;
            transition: .3s ease;
        }
    }
    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
    }
    &:hover {
        color: #B9DCDE;
        .icon-svg {
            stroke: #B9DCDE;
        }
    }
}
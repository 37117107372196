.labadv-functional {
    overflow: hidden;
    background: #F0F8FA;
    padding: 80px 0;
    &__inner {
        display: grid;
        grid-template-columns: 470px 1fr;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__title {
        margin-bottom: 25px;
        color: #74BBBF;
    }
    &__text {
        margin-bottom: 65px;
    }
    &__list {
        .page-list {
            &__item {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__pic {
        display: flex;
        align-items: center;
        img {
            max-width: none;
            width: 110%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .labadv-functional {
        &__inner {
            grid-template-columns: 1fr;
        }
        &__text {
            margin-bottom: 25px;
        }
        &__list {
            .page-list {
                &__item {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &__pic {
            img {
                margin: 0 auto;
                width: 60%;
            }
        }
        &__content{
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: 660px) {
    .labadv-functional {
        &__title {
            margin-bottom: 15px;
        }
        &__pic {
            img {
                width: 100%;
            }
        }
        &__content{
            margin-bottom: 40px;
        }
    }
}

.labadv__form-button{
    margin-top: 20px;
    display: flex;
}

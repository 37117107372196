@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 10 + s;
        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
};

.tickets {
    padding: 80px 0;
    background: #F0F8FA;
    &__title {
        font-weight: 300;
        font-size: 32px;
        line-height: 39px;
        margin-bottom: 55px;
        color: #5D6068;
        max-width: 1070px;
    }
    &__subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #22232E;
        margin-bottom: 30px;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-bottom: 60px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 40px 45px;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        &.aos-animate {
            @include set-delay(6);
        }
    }
    &__timing {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 178px;
        height: 178px;
        border-radius: 50%;
        box-shadow: 5px 5px 15px rgba(38, 40, 61, 0.1);
        margin-bottom: 40px;
        text-align: center;
        color: #9AA5C1;
        &-num {
            font-weight: 300;
            font-size: 64px;
            line-height: 1;
            letter-spacing: -0.02em;
        }
        &-text {
            font-size: 18px;
            line-height: 36px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
        &_orange {
            color: #F0872A;
        }
        &_green {
            color: #52C1C8;
        }
    }
    &__name {
        display: flex;
        align-items: center;
        font-weight: 300;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        color: #22232E;
        margin-bottom: 18px;
        min-height: 62px;
    }
    &__text {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #5D6068;
    }
    &__bottom {
        font-size: 24px;
        line-height: 1.5;
        letter-spacing: -0.02em;

        color: #5D6068;
    }
}

@media screen and (max-width: 1024px) {
    .tickets {
        &__list {
            grid-template-columns: 1fr;
        }
        &__item {
            display: grid;
            grid-template-columns: 150px 1fr;
            grid-auto-rows: min-content;
            column-gap: 20px;
        }
        &__timing {
            width: 150px;
            height: 150px;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            margin-bottom: 0;
        }
        &__name {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            text-align: left;
            margin-bottom: 0;
            min-height: auto;
        }
        &__text {
            text-align: left;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
        }
    }
}

@media screen and (max-width: 660px) {
    .tickets {
        padding: 30px 0;
        &__title {
            font-size: 18px;
            line-height: 128%;
            margin-bottom: 25px;
        }
        &__subtitle {
            font-size: 18px;
            line-height: 128%;
            margin-bottom: 15px;
        }
        &__list {
            gap: 14px;
            margin-bottom: 17px;
        }
        &__item {
            padding: 18px 16px 20px;
            grid-template-columns: 60px 1fr;
            column-gap: 13px;
        }
        &__timing {
            width: 59.74px;
            height: 59.74px;
            &-num {
                font-weight: 500;
                font-size: 18px;
                line-height: 1;
            }
            &-text {
                font-weight: 500;
                font-size: 13px;
                line-height: 1;
                font-weight: 300;
                text-transform: none;
            }
        }
        &__name {
            font-size: 17px;
            line-height: 128%;
        }
        &__text {
            font-weight: 300;
            font-size: 13px;
            line-height: 140%;
        }
        &__bottom {
            font-weight: 300;
            font-size: 15px;
            line-height: 140%;
        }
    }
}
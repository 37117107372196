.modules {
    padding: 150px 0 190px;
    &__title {
        margin-bottom: 65px;
    }
}

.modules-list {
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 120px;
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-child(2n) {
            flex-direction: row-reverse;
        }
    }
    &__content {
        width: 58%;
        max-width: 570px;
    }
    &__pics {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        max-width: 658px;
        width: 50%;
        img {
            width: 45%;
            height: auto;
            &:only-child {
                width: 100%;
            }
            &:nth-child(2) {
                margin-bottom: 50px;
            }
        }
    }
}

.modules-blocks {
    display: flex;
    flex-direction: column;
    &__item {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__title {
        font-weight: 300;
        font-size: 32px;
        line-height: 39px;
        color: #3B3C40;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 1024px) { 
    .modules {
        padding: 80px 0;
    }
    .modules-list {
        &__item {
            flex-direction: column;
            align-items: flex-start;
            &:nth-child(2n) {
                flex-direction: column;
            }
        }
        &__content {
            width: 100%;
            max-width: none;
            margin-bottom: 30px;
        }
        &__pics {
            width: 100%;
            max-width: 500px;
            align-self: center;
        }
    }
    .modules-blocks {
        &__item {
            margin-bottom: 25px;
        }
    }
}

@media screen and (max-width: 660px) { 
    .modules {
        padding: 45px 0;
        background: #F0F8FA;
        overflow: hidden;
        &__title {
            margin-bottom: 25px;
        }
    }
    .modules-blocks {
        &__item {
            margin-bottom: 20px;
        }
        &__title {
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
        }
    }
    .modules-list {
        &__item {
            margin-bottom: 45px;
        }
        &__pics {
            align-self: flex-start;
            width: 460px;
            max-width: none;
        }
    }
}

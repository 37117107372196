.benefit {
    padding: 90px 0;
    &__title {
        margin-bottom: 42px;
    }
}

@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 10 + s;
        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
};

.benefit-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    &-2{
        grid-template-columns: repeat(2, 1fr);
    }
    &__item {
        display: flex;
        flex-direction: column;
        padding: 35px 35px 70px;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        &.aos-animate {
            @include set-delay(6);
        }
        &_simple {
            padding: 35px 35px 40px;
            .benefit-list {
                &__text {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    color: #2A2A2D;
                }
            }
        }
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98px;
        height: 98px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
        margin-bottom: 22px;
        .icon-svg {
            width: 40px;
            height: 60px;
            fill: #74BBBF;
        }
    }
    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #2A2A2D;
        padding-bottom: 12px;
        border-bottom: 1px solid #74BBBF;
        margin-bottom: 23px;
    }
    &__text {
        font-size: 18px;
        line-height: 150%;
    }
}

.benefit-labadv{
    .benefit-list__icon .icon-svg{
        width: 64px;
        height: 64px;
    }
}

@media screen and (max-width: 1024px) {
    .benefit {
        padding: 50px 0;
    }
    .benefit-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 660px) {
    .benefit-list {
        grid-template-columns: 1fr;
        &__item {
            display: grid;
            grid-template-columns: 40px 1fr;
            grid-auto-rows: auto;
            column-gap: 17px;
            row-gap: 6px;
            padding: 0;
            box-shadow: none;
            &_simple {
                row-gap: 0;
                .benefit-list {
                    &__text {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 128%;
                        color: #6C6E75;
                        grid-row: 1 / 2;
                    }
                }
            }
        }
        &__icon {
            align-items: center;
            width: 40px;
            height: 40px;
            box-shadow: none;
            grid-row: 1 / 3;
            grid-column: 1 / 2;
            margin-bottom: 0;
            .icon-svg {
                width: 32px;
                height: 32px;
            }
        }
        &__title {
            grid-column: 2 / 3;
            padding: 0;
            margin-bottom: 0;
            border: none;
            font-size: 20px;
            line-height: 120%;
            color: #41888C;
        }
        &__text {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            font-size: 16px;
            line-height: 140%;
        }

    }
}

.more-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: inherit;
    width: fit-content;
    transition: .3s ease;
    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
    &__icon {
        margin-left: 10px;
        .icon-svg {
            width: 24px;
            height: 24px;
            object-fit: contain;
        }
    }
    &:hover {
        color: #74BBBF;
    }
}
.sources-mars {
    padding: 120px 0 110px;
    &__title {
        margin-bottom: 35px;
    }
    &__text {
        margin-bottom: 40px;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1px;
        background: #D5D5D8;
    }
    &__card {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 121px;
        background: #F0F8FA;
        padding: 26px 32px 25px;
        &:first-child {
            grid-column: 1 / 3;
        }
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        flex-shrink: 0;
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        img {
            width: auto;
            height: auto;
            object-fit: contain;
            object-position: center;
        }
    }
}

@media screen and (max-width: 1400px) {
    .sources-mars {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
        &__card {
            &:first-child {
                grid-column: 1 / 2;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .sources-mars {
        padding: 50px 0 38px;
    }
}

@media screen and (max-width: 660px) {
    .sources-mars {
        &__text {
            margin-bottom: 32px;
        }
        &__list {
            grid-template-columns: 1fr;
            background: #FFFFFF;
            gap: 10px;
        }
        &__card {
            background: transparent;
            min-height: auto;
            padding: 0;
        }
        &__icon {
            width: 45px;
            height: 45px;
            margin-right: 15px;
        }
    }
}

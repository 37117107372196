.grid-list {
    padding: 50px 0 85px;
    background: #F0F8FA;
    &__title {
        margin-bottom: 45px;
    }
    &__subtitle {
        margin-bottom: 33px;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 35px;
        row-gap: 40px;
    }
    &_mars {
        .grid-list {
            &__title {
                max-width: 785px;
            }
            &__list {
                max-width: 860px;
                column-gap: 90px;
                row-gap: 25px;
            }
        }
    }
}

.page-list {
    &__item {
        display: flex;
        align-items: flex-start;
    }
    &__icon {
        width: 18px;
        height: 15px;
        margin-right: 20px;
        margin-top: 5px;
        flex-shrink: 0;
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: none;
            stroke: #74BBBF;
        }
    }
}

@media screen and (max-width: 1024px) {
    .grid-list {
        padding: 50px 0;
        background: #FFFFFF;
        &__list {
            grid-template-columns: 1fr;
            row-gap: 20px;
        }
    }
}

@media screen and (max-width: 660px) {
    .page-list {
        &__icon {
            margin-right: 8px;
        }
    }
}
.post {
    padding: 70px 0 55px;
    &__inner {
        max-width: 770px;
    }
    &__title {
        font-weight: 300;
        font-size: 40px;
        line-height: 120%;
        color: #2A2A2D;
        margin-bottom: 22px;
    }
    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
    }
    &__btns {
        display: flex;
        align-items: center;
        .post-btn {
            margin-right: 11px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__date {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #6C6E75;
        margin-left: 22px;
    }
    &__pic {
        width: 100%;
        overflow: hidden;
        border-radius: 12px;
        margin-bottom: 38px;
        img {
            width: 100%;
            height: auto;
        }
    }
    &__text {
        margin-bottom: 33px;
        h2 {
            font-weight: 300;
            font-size: 32px;
            line-height: 39px;
            margin-bottom: 22px;
            color: #2A2A2D;
        }
        blockquote {
            position: relative;
            margin: 0;
            padding: 11px 16px 12px 12px;
            font-weight: 300;
            font-size: 20px;
            line-height: 140%;
            color: #3B3C40;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 54px;
                height: 47px;
                background: url(../../images/svg/quotes.svg) no-repeat center;
                z-index: -1;
            }
        }
    }
}

.post-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 17px;
    &__item {
        border-radius: 5px;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
        }
    }
    h2 {
        margin-bottom: 5px;
        grid-column: 1 / -1;
    }
}

.post-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    outline: none;
    border: none;
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 2px;
    cursor: pointer;
    &__icon {
        width: 100%;
        height: 100%;
        img {
            width: auto;
            height: auto;
        }
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more.ya-share2__link_more-button-type_short {
        background: transparent;
    }
    .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__icon_more {
        background-image: url(../../images/svg/share.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px 15px;
    }
}

.post-author {
    display: grid;
    grid-template-columns: 68px 225px;
    grid-auto-rows: min-content;
    column-gap: 12px;
    padding: 15px 0 20px;
    border-top: 1px solid #F2F2F2;
    &__pic {
        width: 100%;
        height: 68px;
        border-radius: 50%;
        overflow: hidden;
        border: 6px solid #F0F8FA;
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        font-weight: 500;
        font-size: 11px;
        line-height: 128%;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #C2C2C2;
    }
    &__name {
        font-size: 15px;
        line-height: 128%;
        color: #3B3C40;
    }
}

@media screen and (max-width: 660px) {
    .post {
        padding: 25px 0;
        &__inner {
            display: flex;
            flex-direction: column;
        }
        &__title {
            font-size: 28px;
            line-height: 120%;
            margin-bottom: 40px;
        }
        &__info {
            order: -1;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 35px;
        }
        &__date {
            margin-left: 0;
        }
        &__pic {
            margin-bottom: 22px;
        }
        &__text {
            h2 {
                font-weight: 400;
                font-size: 18px;
                line-height: 128%;
                color: #3B3C40;
            }
            blockquote {
                font-size: 16px;
                line-height: 140%;
            }
        }
    }
    .post-gallery {
        grid-template-columns: 1fr 1fr;
    }
}

@media print {
    .header,
    .footer,
    .intro,
    .post-gallery,
    .relative-posts {
        display: none;
    }
    .post {
        padding: 20px 0;
        &__btns {
            display: none;
        }
        &__pic {
            display: none;
        }
        &__text {
            margin-bottom: 0;
        }
        &__date {
            margin-left: 0;
        }
        &__back {
            display: none;
        }
    }
}

.for-visitor {
    padding: 90px 0 110px;
    background: #F0F8FA;
    overflow: hidden;
    &__inner {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__pic {
        position: absolute;
        top: -290px;
        left: 0;
        height: calc(100% + 500px);
        img {
            width: auto;
            height: 100%;
        }
    }
    &__content {
        max-width: 370px;
    }
    &__title {
        margin-bottom: 55px;
    }
    &__list {
        display: flex;
        flex-direction: column;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 35px;
        height: 40px;
        flex-shrink: 0;
        margin-right: 25px;
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: #74BBBF;
        }
    }
    &__text {
        font-size: 16px;
        line-height: 20px;
        color: #3B3C40;
    }
}

@media screen and (max-width: 1400px) {
    .for-visitor {
        &__pic {
            left: -180px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .for-visitor {
        position: relative;
        &__inner {
            position: static;
        }
        &__pic {
            left: -50px;
            top: -160px;
            height: calc(100% + 200px);
            width: 40%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .for-visitor {
        padding: 50px 0 370px;
        &__content {
            max-width: none;
        }
        &__pic {
            left: 50%;
            transform: translate3d(-50%, 0, 0) !important;
            top: auto;
            bottom: -160px;
            width: 100%;
            height: auto;
            max-width: 270px;
            img {
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .for-visitor {
        &__pic {
            &[data-aos="fade-right"] {
                transform: translate3d(-50%, 0, 0) !important;
            }
        }
    }
}
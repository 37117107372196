.footer-top {
    padding: 70px 0 105px;
    background: #74BBBF;
    color: #FFFFFF;
    &__inner {
        display: grid;
        grid-template-columns: 312px 1fr;
        column-gap: 90px;
    }
    &__col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__title {
        font-weight: 500;
        font-size: 36px;
        line-height: 120%;
        margin-bottom: 18px;
    }
    &__text {
        font-size: 16px;
        line-height: 20px;
    }
}

.footer-contacts {
    display: flex;
    flex-direction: column;
    &__link {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #FFFFFF;
        &:hover {
            color: #B9DCDE;
        }
    }
}

.footer-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 20px;
    &__field_wide {
        grid-column: 1 / -1;
    }
    &__submit {
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__status-message {
        grid-column: 1 / -1;
    }
}


.footer-bottom {
    padding: 18px 0;
    background: #3B3C40;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 120%;
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__copyright {
        margin-bottom: 9px;
    }
    &__link {
        text-decoration: underline;
        margin-right: 18px;
        color: #FFFFFF;
        opacity: 0.7;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            opacity: 1;
        }
    }
}

.pixelplus {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 150%;
    opacity: 0.5;
    &__logo {
        margin-right: 10px;
    }
    &__links {
        a {
            color: #FFFFFF;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 1024px) { 
    .footer-top {
        padding: 40px 0;
        &__inner {
            grid-template-columns: 1fr;
            row-gap: 30px;
        }
        &__content {
            margin-bottom: 10px;
        }
    }
    .footer-contacts {
        &__link {
            font-weight: 400;
        }
    }
    .footer-bottom {
        &__inner {
            flex-direction: column;
            align-items: flex-start;
        }
        &__content {
            margin-bottom: 35px;
        }
    }
}

@media screen and (max-width: 1024px) { 
    .footer-form {
        grid-template-columns: 1fr;
        column-gap: 0;
        &__field {
            &_wide {
                grid-column: auto;
            }
        }
        &__submit {
            grid-column: auto;
        }
    }
}

@media screen and (max-width: 660px) { 
    .footer-top {
        padding: 32px 0;
        &__inner {
            row-gap: 25px;
        }
        &__title {
            font-weight: 300;
            font-size: 32px;
            line-height: 120%;
            margin-bottom: 14px;
            max-width: none;
        }
    }
    .footer-contacts {
        &__link {
            font-size: 20px;
            line-height: 150%;
        }
    }
    .footer-form {
        &__submit {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
    }
    .footer-bottom {
        padding: 35px 0 40px;
        &__copyright {
            margin-bottom: 14px;
        }
        &__links {
            display: flex;
            flex-direction: column;
        }
        &__link {
            margin-right: 0;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.about-blocks {
    padding: 80px 0 40px;
    background: #F0F8FA;
    &__inner {
        display: flex;
        flex-direction: column;
    }
    &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 49px 100px;
        padding-right: 0;
        background: #FFFFFF;
        border-radius: 15px;
        margin-bottom: 45px;
        overflow: hidden;
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-child(2n) {
            flex-direction: row-reverse;
            padding-left: 0;
            padding-right: 100px;
            .about-blocks {
                &__pic {
                    border-radius: 0 15px 15px 0;
                }
            }
        }
    }
    &__content {
        width: 39%;
        max-width: 360px;
    }
    &__pic {
        width: 60%;
        max-width: 640px;
        overflow: hidden;
        border-radius: 15px 0 0 15px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        margin-bottom: 35px;
    }
    &__text {
        p {
            margin-bottom: 22px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.about-strategy {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    z-index: 1;
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__inner {
        padding: 64px 42px;
        background: #FFFFFF;
        border-radius: 20px;
        margin-bottom: -115px;
    }
    &__title {
        margin-bottom: 35px;
        max-width: 400px;
    }
    &__text {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        row-gap: 20px;
    }
}

.about-contacts {
    padding: 190px 0 48px;
    background: #F0F8FA;
    &__title {
        margin-bottom: 35px;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;
        margin-right: 25px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__icon {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        margin-right: 12px;
        .icon-svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            fill: #3B3C40;
        }
    }
    &__link {
        color: #6C6E75;
    }
}

.map {
    img {
        width: 100%;
        height: auto;
        max-width: none;
        object-fit: cover;
    }
}

@media screen and (max-width: 1400px) {
    .about-blocks {
        &__block {
            padding: 50px;
            padding-right: 0;
            &:nth-child(2n) {
                padding-left: 0;
                padding-right: 50px;
                .about-blocks {
                    &__pic {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }
            }
        }
        &__pic {
            margin-left: 20px;
        }
    }   
}

@media screen and (max-width: 1023px) {
    .about-blocks {
        padding: 0 0 20px;
        &__block {
            padding: 35px 0 7px;
            flex-direction: column;
            align-items: flex-start;
            background: #F0F8FA;
            margin-bottom: 0;
            &:nth-child(2n) {
                flex-direction: column;
                padding-right: 0;
                .about-blocks {
                    &__pic {
                        margin-right: 0;
                        border-radius: 15px;
                    }
                }
            }
        }
        &__content {
            width: 100%;
            max-width: none;
            margin-bottom: 20px;
        }
        &__pic {
            width: 100%;
            max-width: none;
            border-radius: 15px;
            margin: 0;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .about-strategy {
        justify-content: flex-start;
        min-height: auto;
        background: #F0F8FA;
        &__bg {
            position: static;
            width: 100%;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__inner {
            background: transparent;
            padding: 35px 0;
            margin-bottom: 0;
        }
        &__text {
            grid-template-columns: 1fr;
        }
    }
    .about-contacts {
        padding: 35px 0;
        background: #FFFFFF;
        &__list {
            flex-direction: column;
            align-items: flex-start;
        }
        &__item {
            margin-right: 0;
            margin-bottom: 18px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .map {
        width: 100%;
        height: 500px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .about-blocks {
        &__title {
            margin-bottom: 13px;
        }
    }
    .about-strategy {
        &__bg {
            width: 100%;
            height: 180px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__title {
            margin-bottom: 13px;
        }
    }
    .about-contacts {
        &__title {
            margin-bottom: 13px;
        }
    }
}
.left-pic {
    padding: 145px 0 110px;
    overflow: hidden;
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__pic {
        width: 53%;
        max-width: 626px;
        img {
            width: auto;
            height: auto;
        }
        &_tenantlab{
            position: relative;
            flex-grow: 1;
            img{
                position: absolute;
                top: -280px;
                max-width: calc(100% + 80px);
                left: 0;
                @media(max-width: 1400px){
                    width: calc(100% + 70px)!important;
                    top: -150px;
                }
                @media(max-width: 1024px){
                    position: static;
                    width: 90%!important;
                    margin: 50px auto 0;
                    right: auto;
                    top: auto;
                }
                @media(max-width: 600px){
                    width: 100%!important;
                    margin: 0 auto;
                }
            }
        }
    }
    &__content {
        width: 44%;
        max-width: 540px;
    }
    &__title {
        margin-bottom: 35px;
    }
    &__text {
        margin-bottom: 25px;
        h3 {
            font-weight: 300;
            font-size: 22px;
            line-height: 150%;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
    }
    &__grid-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 32px;
    }
    &__grid-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }
    &__swiper {
        position: relative;
        width: 52%;
        margin-right: 100px;
        &::before {
            content: "";
            position: absolute;
            top: 125px;
            left: 0;
            width: calc(100% - 65px);
            height: 35%;
            border: 35px solid #D4EDEE;
            border-bottom: none;
            border-right: none;
            z-index: -1;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 150px;
            right: 0;
            width: calc(100% - 65px);
            height: 35%;
            border: 35px solid #D4EDEE;
            border-top: none;
            border-left: none;
            z-index: -1;
        }
        .swiper-slide {
            position: relative;
            height: initial;
            display: flex;
            flex-direction: column;
            img {
                flex-grow: 1;
                object-fit: contain;
            }
            p {
                display: flex;
                align-items: center;
                min-height: 49px;
                padding-left: 135px;
                font-size: 16px;
                line-height: 140.52%;
                color: #74BBBF;
            }
        }
    }
    &__btns {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        background: #FFF;
    }
    &__prev,
    &__next {
        background: #F0F8FA;
    }
}

@media screen and (max-width: 1024px) {
    .left-pic {
        padding: 50px 0;
        &__inner {
            flex-direction: column-reverse;
        }
        &__content {
            width: 100%;
            max-width: none;
            margin-bottom: 20px;
        }
        &__pic {
            width: 100%;
        }
        &__swiper {
            width: 100%;
        }
    }
}

@media screen and (max-width: 660px) {
    .left-pic {
        padding: 35px 0 20px;
        &__title {
            margin-bottom: 13px;
        }
        &__grid-title {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            color: #6C6E75;
            margin-bottom: 25px;
        }
        &__grid-list {
            grid-template-columns: 1fr;
            row-gap: 20px;
        }
        &__text {
            p {
                margin-bottom: 13px;
            }
            h3 {
                font-weight: normal;
                font-size: 18px;
                line-height: 128%;
                color: #3B3C40;
                margin-bottom: 6px;
            }
        }
        &__prev {
            background: #F0F8FA;
            margin-right: 5px;
        }
        &__next {
            background: #F0F8FA;
        }
        &__swiper {
            .swiper-slide {
                p {
                    min-height: 35px;
                    padding-left: 85px;
                }
            }
        }
        &_padding {
            padding: 50px 0 20px;
            .left-pic {
                &__pic {
                    img {
                        width: 100%;
                    }
                }
            }
        }
        &_overflow {
            overflow: hidden;
            .left-pic {
                &__content {
                    margin-bottom: 20px;
                }
                &__pic {
                    img {
                        width: 457.54px;
                        max-width: none;
                    }
                }
            }
        }
    }
}

.documents{
    padding: 80px 0;
    @media(max-width: 600px){
        padding: 20px 0 50px;
    }
    &__inner3{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-top: 48px;
        @media(max-width: 768px){
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }
    &__inner{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        margin-top: 48px;
        @media(max-width: 768px){
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }
    &__item{
        width: 100%;
        padding: 16px;
        border: 2px solid var(--gray-200, #D5D5D8);
        color: #74BBBF;
        display: flex;
        gap: 10px;
        cursor: pointer;
        outline: none;
        transition: .3s ease;
        svg{
            width: 40px;
            height: 40px;
        }
        &_title{
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 5px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
        }
        &_size{
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
        &:hover{
            border: 2px solid var(--turquoise-200, #74BBBF);
        }
        &:active{
            border: 2px solid var(--turquoise-300, #41888C);
        }
        &_description{
            width: calc(100% - 50px);
        }
    }
}

@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 6 + s;
        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
};

.bp-functional {
    padding: 55px 0;
    overflow: hidden;
    &__title {
        margin-bottom: 50px;
    }
    &__description {
        margin-bottom: 15px;
    }
    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1000px;
        height: 500px;
        margin: 0 auto;
        z-index: 1;
        &.aos-animate {
            .bp-functional {
                &__item {
                    opacity: 1;
                    @include set-delay(7);
                }
                &__line {
                    &::after {
                        width: 204.79px;
                        transition-delay: 1.5s;
                    }
                }
            }
        }
    }
    &__wrapper-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 205px;
        height: 205px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 5px 5px 15px rgba(38, 40, 61, 0.1);
        font-size: 48px;
        line-height: 96px;
        letter-spacing: -0.02em;
        color: #52C1C8;
        z-index: 2;
    }
    &__item {
        position: absolute;
        display: flex;
        align-items: center;
        transition: .3s linear;
        opacity: 0;
        &:nth-child(1) {
            left: -40px;
            top: 80px;
            .bp-functional {
                &__item-wrapper {
                    max-width: 164px;
                }
                &__text {
                    text-align: right;
                }
            }
        }
        &:nth-child(2) {
            left: -35px;
            top: 265px;
            .bp-functional {
                &__item-wrapper {
                    max-width: 217px;
                }
                &__text {
                    text-align: right;
                }
                &__line {
                    &::before {
                        top: 9px;
                        transform: translateY(0);
                    }
                    &::after {
                        top: -50px;
                        left: calc(100% + 20px);
                        transform: rotate(-32deg);
                        
                    }
                }
            }
        }
        &:nth-child(3) {
            left: 160px;
            top: 370px;
            .bp-functional {
                &__item-wrapper {
                    max-width: 156px;
                }
                &__text {
                    text-align: right;
                }
                &__line {
                    &::after {
                        top: -80px;
                        left: calc(100% - 35px);
                        transform: rotate(-65.68deg);
                        
                    }
                }
            }
        }
        &:nth-child(4) {
            left: 50%;
            top: 460px;
            transform: translateX(-50%);
            .bp-functional {
                &__item-wrapper {
                    max-width: 127px;
                }
                &__text {
                    text-align: center;
                }
                &__line {
                    &::before {
                        top: -20px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    &::after {
                        top: -140px;
                        left: 50%;
                        transform: translateX(-50%) rotate(-90deg);
                        
                    }
                }
            }
        }
        &:nth-child(5) {
            right: 190px;
            top: 375px;
            .bp-functional {
                &__item-wrapper {
                    max-width: 227px;
                }
                &__text {
                    text-align: left;
                }
                &__line {
                    &::before {
                        top: 50%;
                        left: -30px;
                        transform: translateY(-50%);
                    }
                    &::after {
                        top: -80px;
                        left: auto;
                        right: calc(100% + -35px);
                        transform: rotate(-112.68deg);
                        
                    }
                }
            }
        }
        &:nth-child(6) {
            right: -35px;
            top: 265px;
            .bp-functional {
                &__item-wrapper {
                    max-width: 227px;
                }
                &__text {
                    text-align: left;
                }
                &__line {
                    &::before {
                        top: 9px;
                        left: -25px;
                        transform: translateY(0);
                    }
                    &::after {
                        top: -55px;
                        left: auto;
                        right: calc(100% + 10px);
                        transform: rotate(-143.68deg);
                      
                    }
                }
            }
        }
        &:nth-child(7) {
            right: -70px;
            top: 80px;
            .bp-functional {
                &__item-wrapper {
                    max-width: 227px;
                }
                &__text {
                    text-align: left;
                }
                &__line {
                    &::before {
                        top: 50%;
                        left: -25px;
                        transform: translateY(-50%);
                    }
                    &::after {
                        top: 50%;
                        transform: translateY(-50%);
                        left: auto;
                        right: calc(100% + 35px);
                    }
                }
            }
        }
    }
    &__item-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 56px;
    }
    &__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        width: 100%;
    }
    &__line {
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -25px;
            display: block;
            width: 12.14px;
            height: 12.14px;
            border-radius: 50%;
            background: #52C1C8;
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% + 50px);
            display: block;
            height: 2px;
            width: 0;
            transition: .3s ease;
            border-bottom: 2px dotted #5D5F6B;
            z-index: -1;
        }
    }
}

@media screen and (max-width: 1400px) {
    .bp-functional {
        &__wrapper {
            width: 834px;
            height: 420px;
            &.aos-animate {
                .bp-functional {
                    &__line {
                        &::after {
                            width: 120.79px;
                        }
                    }
                }
            }
        }
        &__item {
            &:nth-child(2) {
                left: -45px;
                top: 210px;
                .bp-functional {
                    &__line {
                        &::after {
                            top: -25px;
                        }
                    }
                }
            }
            &:nth-child(3) {
                left: 120px;
                top: 310px;
                .bp-functional {
                    &__line {
                        &::after {
                            top: -45px;
                            left: calc(100% - 10px);
                        }
                    }
                }
            }
            &:nth-child(4) {
                top: 375px;
                .bp-functional {
                    &__line {
                        &::after {
                            top: -90px;
                        }
                    }
                }
            }
            &:nth-child(5) {
                right: 140px;
                top: 310px;
                .bp-functional {
                    &__line {
                        &::after {
                            top: -45px;
                            right: calc(100% + -10px);
                        }
                    }
                }
            }
            &:nth-child(6) {
                right: -45px;
                top: 210px;
                .bp-functional {
                    &__line {
                        &::after {
                            top: -30px;
                            right: calc(100% + 15px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .bp-functional {
        &__title {
            margin-bottom: 6px;
        }
        &__wrapper {
            width: 100%;
            height: auto;
            align-items: flex-start;
            &.aos-animate {
                .bp-functional {
                    &__list {
                        &::before {
                            height: calc(100% + 20px);
                        }
                    }
                }
            }
        }
        &__wrapper-title {
            width: 100%;
            height: 120px;
            font-size: 42px;
            border-radius: 18px;
            margin-bottom: 35px;
        }
        &__list {
            position: relative;
            align-items: flex-start;
            padding: 0 60px;
            &::before {
                content: "";
                position: absolute;
                top: -33px;
                left: 18px;
                width: 1px;
                height: 0;
                border-left: 2px dotted #5D5F6B;
                transition: 1.16667s ease;
            }
        }
        &__item {
            position: static;
            transform: translateX(0) !important;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__item-wrapper {
            max-width: none !important;
            min-height: auto !important;
        }
        &__text {
            font-weight: 400;
            font-size: 18px;
            color: #3B3C40;
            text-align: left !important;
        }
        &__line {
            &::before {
                top: 8px !important;
                left: -20px !important;
                right: auto;
                transform: translateY(0) !important;
                transform: translateX(0) !important;
            }
            &::after {
                top: 13px !important;
                left: -40px !important;
                width: 20px !important;
                z-index: -1;
                transform: rotate(0) !important;
                transition-delay: 0s !important;
            }
        }
    }
}
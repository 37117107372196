@import "aos/src/sass/aos";
@import "node_modules/swiper/swiper";

// base styles

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    background-color: #FFFFFF;
    color: #6C6E75;
    &.no-scroll {
        overflow: hidden;
    }
}

* {
    box-sizing: border-box;
    &::before,
    &::after {
        box-sizing: border-box;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
}

a {
    text-decoration: none;
    transition: .3s ease;
}

ul {
    padding: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    li {
        display: block;
        list-style: none;
        padding: 0;
    }
}

img {
    display: block;
    max-width: 100%;
    image-rendering: -webkit-optimize-contrast;
}

.icon-svg {
    display: block;
    max-width: 100%;
}

.main {
    flex-grow: 1;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1170px;
    .container {
        padding: 0 !important;
    }
    @media (min-width: 1500px){

    }
}

.section-title {
    font-weight: 300;
    font-size: 64px;
    line-height: 120%;
    color: #2A2A2D;
    &_small {
        font-weight: 300;
        font-size: 40px;
        line-height: 120%;
        color: #2A2A2D;
    }
}

.gray-bg {
    background: #EBEBEB;
}

.color-green {
    color: #74BBBF;
}

.swiper {
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 49px;
        cursor: pointer;
        outline: none;
        margin-right: 16px;
        transition: .3s ease;
        &:last-child {
            margin-right: 0;
        }
        .icon-svg {
            width: 8px;
            height: 15px;
            fill: none;
            stroke: #74BBBF;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}

.pages-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    a {
        color: #74BBBF;
    }
}

button {
    margin: 0;
    appearance: none;
}

section {
    background: #FFFFFF;
    &.green-bg {
        background: #F0F8FA;
    }
}

.fw-banner {
    width: 100%;
    height: 645px;
    background: transparent;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.btn {
    display: block;
    font-family: inherit;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    border: none;
    box-shadow: none;
    cursor: pointer;
    width: fit-content;
    padding: 12px 40px;
    color: #6C6E75;
    background: transparent;
    border: 1px solid #D5D5D8;
    border-radius: 2px;
}

.video-banner {
    video {
        width: 100%;
        height: auto;
    }
}

.grecaptcha-badge {
    z-index: 10;
}

// IMPORT

@import "includes/form-group";
@import "includes/header";
@import "includes/footer";
@import "includes/intro";
@import "includes/more-link";
@import "includes/column-text";
@import "includes/float-pic";
@import "includes/center-pic";
@import "includes/left-pic";
@import "includes/posts-swiper";
@import "includes/cases-swiper";
@import "includes/left-pic-bg";
@import "includes/grid-list";
@import "includes/modules";
@import "includes/animation";
@import "includes/parallax";
@import "includes/press";
@import "includes/post";
@import "includes/back-link";
@import "includes/relative-posts";
@import "includes/card-advantages";
@import "includes/benefit";
@import "includes/for-visitor";
@import "includes/card-types";
@import "includes/reports-system";
@import "includes/swiper-design";
@import "includes/sources-mars";
@import "includes/mars-functional";
@import "includes/labadv-functional";
@import "includes/tabs-swiper";
@import "includes/right-pic";
@import "includes/b2b-crm";
@import "includes/tenant-success";
@import "includes/right-pic-bg";
@import "includes/circle-list";
@import "includes/float-list";
@import "includes/info-swipers";
@import "includes/bp-functional";
@import "includes/tickets";
@import "includes/about-blocks";
@import "includes/select";
@import "includes/scroll-down";
@import "includes/tenant-lab";
@import "includes/video-instruction";
@import "includes/documents";
@import "includes/labloyalty";
@import "includes/vacancies";
@import "includes/gift-card";

// RESPONSIVE

@media screen and (max-width: 1400px) {
    .container {
        max-width: 1120px;
        padding:  0 95px;
    }
    .section-title {
        font-size: 48px;
    }
}

@media screen and (max-width: 1024px) {
    .fw-banner {
        height: 400px;
    }
}

@media screen and (max-width: 660px) {
    .container {
        padding: 0 16px;
    }
    body {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
    }
    .section-title {
        font-size: 32px;
        line-height: 120%;
        padding-top: 70px;
        margin-top: -50px;
        &_small {
            font-size: 24px;
            line-height: 120%;
            padding-top: 70px;
            margin-top: -50px;
        }
    }
    [data-aos="fade-up"],
    [data-aos="fade-left"],
    [data-aos="fade-right"] {
        opacity: 1 !important;
        transform: translate3d(0, 0, 0) !important;
    }
    .swiper {
        &__btn {
            width: 32px;
            height: 32px;
            .icon-svg {
                width: 10px;
                height: 10px;
            }
        }
    }
    .fw-banner {
        height: 270px;
    }
}

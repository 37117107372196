.float-pic {
    position: relative;
    padding: 135px 0 165px;
    &__content {
        display: flex;
        flex-direction: column;
        max-width: 560px;
        width: 48%;
    }
    &__title {
        margin-bottom: 42px;
    }
    &__text {
        margin-bottom: 55px;
        p {
            margin-bottom: 18px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__pic {
        position: absolute;
        top: 135px;
        right: 0;
        bottom: 165px;
        width: 48%;
        overflow: hidden;
        border-radius: 20px 0 0 20px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media screen and (max-width: 1024px) {
    .float-pic {
        overflow-x: hidden;
        padding: 50px 0;
        &__inner {
            display: flex;
            flex-direction: column-reverse;
        }
        &__pic {
            position: static;
            width: calc(100% + 20px);
            margin-bottom: 20px;
        }
        &__content {
            width: 100%;
            max-width: none;
        }
    }

}

@media screen and (max-width: 660px) {
    .float-pic {
        padding: 35px 0;
        &__title {
            margin-bottom: 18px;
        }
        &__text {
            margin-bottom: 18px;
        }
    }
}

.right-pic {
    padding: 135px 0 125px;
    &__inner {
        display: flex;
        align-items: stretch;
    }
    &__content {
        width: 58%;
        max-width: 675px;
        flex-grow: 1;
        margin-right: 95px;
    }
    &__pic {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-width: 100%;
        }
    }
    &__title {
        margin-bottom: 30px;
    }
    &__text {
        h3 {
            font-weight: 300;
            font-size: 22px;
            line-height: 150%;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 16px;
        }
        ul {
            padding-left: 20px;
            li {
                display: list-item;
                list-style: disc;
            }
        }
    }
    &__link {
        margin-top: 30px;
    }
    &_absolute {
        .right-pic {
            &__content {
                max-width: 554px;
                margin-right: 20px;
            }
            &__pic {
                position: relative;
                width: 50%;
                img {
                    position: absolute;
                    width: 100%;
                    height: auto;
                    max-width: none;
                    left: 0;
                    bottom: -125px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .right-pic {
        padding-top: 50px;
        padding-bottom: 0;
        overflow: hidden;
        &__inner {
            flex-direction: column;
            align-items: center;
        }
        &__content {
            width: 100%;
            max-width: none;
            margin-right: 0;
            margin-bottom: 20px;
        }
        &__pic {
            margin-bottom: -40px;
        }
        &_absolute {
            .right-pic {
                &__content {
                    max-width: none;
                    margin-right: 0;
                }
                &__pic {
                    width: 100%;
                    margin-bottom: 0;
                    img {
                        position: static;
                    }
                }
            }
        }
        &_padding {
            padding: 50px 0 20px;
            .right-pic {
                &__pic {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .right-pic {
        padding-top: 35px;
        &__title {
            margin-bottom: 13px;
        }
        &__text {
            h3 {
                font-weight: normal;
                font-size: 18px;
                line-height: 128%;
                color: #3B3C40;
                margin-bottom: 6px;
            }
        }
        &__pic {
            img {
                margin: 0 auto;
            }
        }
    }
}

.right-pic{
    &__labloyalty{
        padding: 260px 0;
        @media(max-width: 1400px){
            padding: 180px 0;
        }
        @media(max-width: 1024px){
            padding: 50px 0;
        }
        .right-pic{
            &__pic{
                flex-grow: 1;
                position: relative;
                img{
                    position: absolute;
                    width: calc(100% + 200px);
                    object-fit: contain;
                    height: auto;
                    right: -80px;
                    max-width: initial;
                    top: -110px;
                    @media(max-width: 1400px){
                        width: calc(100% + 300px);
                        right: -130px;
                        top: -50px;
                    }
                    @media(max-width: 1024px){
                        position: relative;
                        width: 90%;
                        top: auto;
                        right: auto;
                        margin: 50px auto;
                    }
                    @media(max-width: 600px){
                        margin: 0 auto 50px;
                    }
                }
            }
        }
    }
}

.column-text {
    padding: 90px 0 85px;
    overflow: hidden;
    &__inner {
        position: relative;
        display: grid;
        gap: 60px;
        grid-template-columns: 1fr 270px;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -85px;
            height: 1px;
            width: 300%;
            background: #D5D5D8;
        }
    }
    &__col {
        line-height: 150%;
        span {
            color: #74BBBF;
        }
    }
}

@media screen and (max-width: 1024px) {
    .column-text {
        padding: 50px 0;
        &__inner {
            grid-template-columns: 1fr;
            gap: 18px;
            &::after {
                bottom: -50px;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .column-text {
        padding: 40px 0;
    }
}
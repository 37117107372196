.card-types {
    position: relative;
    display: flex;
    z-index: 1;
    &__sticky {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        flex-shrink: 1;
        flex-grow: 2;
        flex-basis: auto;
        background: #74BBBF;
        color: #FFFFFF;
        height: 100vh;
        padding-right: 64px;
        z-index: 2;
    }
    &__list {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        flex-grow: 3;
        flex-basis: auto;
    }
    &__item {
        display: flex;
        align-items: center;
        height: calc(100vh - 40px);
        transform: translate3d(0,0,0);
        margin: 20px 0;
        padding-left: 30px;
        overflow: hidden;
        &.aos-animate {
            .card-types {
                &__wrapper {
                    &::before {
                        transition-delay: .2s;
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
                &__pic {
                    transition-delay: .2s;
                    opacity: 1;
                    transform: translateY(0);
                }
                &__item-text {
                    transition-delay: .2s;
                    opacity: 1;
                    transform: translateY(0);
                    .card__form-button{
                        margin-top: 20px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
    &__wrapper {
        position: relative;
        width: 85%;
        max-width: 670px;
        padding: 38px 45px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 100%;
            height: 355px;
            transform: translateX(-100%);
            background: #F0F8FA;
            z-index: -1;
            opacity: 0;
            transition: .3s ease;
        }
        &_column {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .card-types {
                &__pic {
                    margin-bottom: 27px;
                }
                &__item-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span {
                        display: block;
                        margin-bottom: 7px;
                    }
                }
            }
        }
        &_pic-left {
            display: flex;
            align-items: flex-end;
            .card-types {
                &__pic {
                    width: 41%;
                    max-width: 235px;
                    margin-right: 28px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &__item-text {
                    margin-bottom: 100px;
                    span {
                        display: block;
                        margin-bottom: 13px;
                    }
                    p {
                        max-width: 280px;
                    }
                }
            }
        }
        &_small-text {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .card-types {
                &__pic {
                    width: 100%;
                    max-width: 350px;
                }
                &__item-text {
                    flex-shrink: 1;
                    margin-bottom: 175px;
                }
            }
        }
    }
    &__pic {
        transform: translateY(100%);
        opacity: 0;
        transition: .3s ease;
    }
    &__item-text {
        transform: translateY(100%);
        opacity: 0;
        transition: .3s ease;
        span {
            font-weight: 300;
            font-size: 22px;
            line-height: 140.52%;
        }
        p {
            font-weight: 300;
            font-size: 18px;
            line-height: 140.52%;
        }
    }
    &__title {
        margin-bottom: 65px;
        max-width: 410px;
        color: #FFFFFF;
    }
    &__nav {
        display: flex;
        flex-direction: column;
        max-width: 331px;
    }
    &__link {
        display: flex;
        align-items: flex-start;
        color: #B9DCDE;
        font-weight: 300;
        font-size: 30px;
        line-height: 39px;
        margin-bottom: 35px;
        &:last-child {
            margin-bottom: 0;
        }
        &.active {
            color: #FFFFFF;
            .icon-svg {
                stroke: #FFFFFF;
            }
        }
    }
    &__icon {
        width: 18px;
        height: 36px;
        margin-right: 20px;
        flex-shrink: 0;
        .icon-svg {
            width: 100%;
            height: 100%;
            fill: none;
            transition: .3s ease;
            stroke: #B9DCDE;
        }
    }
    &__swiper {
        width: 100%;
    }
    .swiper-wrapper {
        flex-direction: column;
        box-sizing: border-box;
    }
}

.card-types__list-item {
    text-align: left;
    margin-bottom: 8px;
}

@media screen and (max-width: 1400px) {
    .card-types {
        &__title {
            font-size: 30px;
            margin-bottom: 30px;
        }
        &__nav {
            max-width: 250px;
        }
        &__link {
            font-size: 20px;
            line-height: 140%;
            margin-bottom: 10px;
        }
        &__icon {
            width: 15px;
            height: 24px;
        }
        &__wrapper {
            &_small-text {
                .card-types {
                    &__pic {
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .card-types {
        flex-direction: column;
        &__sticky {
            position: static;
            height: auto;
            padding: 50px 95px 0;
            align-items: flex-start;
            color: #3B3C40;
            background: #FFFFFF;
        }
        &__title {
            color: #3B3C40;
        }
        &__nav {
            max-width: none;
        }
        &__link {
            color: #6C6E75;
            .icon-svg {
                stroke: #74BBBF;
            }
            &.active {
                color: #6C6E75;
                .icon-svg {
                    stroke: #74BBBF;
                }
            }
            &.show {
                color: #74BBBF;
            }
        }
        &__list {
            padding: 20px 95px 40px;
            flex-basis: auto;
            width: 100%;
        }
        &__swiper {
            width: 100%;
        }
        &__item {
            height: auto;
            width: 100%;
            padding: 0;
            margin: 0;
            background: #FFFFFF;
        }
        &__wrapper {
            width: 100%;
            max-width: none;
            padding: 20px 0;
            align-items: flex-start;
            &_column {
                .card-types {
                    &__pic {
                        margin-bottom: 10px;
                    }
                    &__item-text {
                        align-items: flex-start;
                        text-align: left;
                        span {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 120%;
                            margin-bottom: 7px;
                        }
                        p {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }
                }
            }
            &_pic-left {
                flex-direction: column;
                .card-types {
                    &__pic {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                    &__item-text {
                        align-items: flex-start;
                        text-align: left;
                        margin-bottom: 0;
                        span {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 120%;
                            margin-bottom: 7px;
                        }
                        p {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }
                }
            }
            &_small-text {
                flex-direction: column;
                .card-types {
                    &__pic {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                    &__item-text {
                        align-items: flex-start;
                        text-align: left;
                        margin-bottom: 0;
                        span {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 120%;
                            margin-bottom: 7px;
                        }
                        p {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }
                }
            }
            &::before {
                display: none;
            }
        }
        &__bg {
            display: none;
        }
        &__pic {
            max-width: 260px;
            margin-bottom: 10px;
        }
        .swiper-wrapper {
            flex-direction: row;
        }
    }
}

@media screen and (max-width: 660px) {
    .card-types {
        &__sticky {
            padding: 50px 16px 0;
        }
        &__list {
            padding: 0 16px 40px;
        }
        &__link {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
        }
        &__icon {
            margin-right: 8px;
        }
    }
}

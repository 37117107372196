@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 10 + s;
        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
};

.reports-system {
    padding: 120px 0;
    overflow: hidden;
    &__inner {
        display: flex;
        align-items: center;
    }
    &__content {
        max-width: 570px;
    }
    &__title {
        margin-bottom: 22px;
    }
    &__pic {
        margin-right: 50px;
        max-width: 50%;
        img {
            width: calc(100% - 80px);
            height: auto;
            max-width: none;
        }
    }
    &__list {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
        row-gap: 25px;
        .benefit-list {
            &__item {
                padding: 28px 18px 38px 30px;
                &.aos-animate {
                    @include set-delay(4);
                }
            }
            &__text {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .reports-system {
        padding: 50px 0;
        &__inner {
            flex-direction: column;
            align-items: flex-start;
        }
        &__content {
            max-width: none;
        }
        &__pic {
            order: 3;
            margin: 20px auto 0;
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .reports-system {
        &__list {
            grid-template-columns: 1fr;
        }
        .benefit-list {
            &__item {
                padding: 0;
            }
        }
    }
}

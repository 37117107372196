@mixin set-delay($els) {
    @for $i from 1 through $els {
        $result: $i / 10 + s;
        &:nth-child(#{$i}) {
            transition-delay: $result;
        }
    }
};

.circle-list {
    padding: 50px 0 0;
    background: #EBEBEB;
    overflow: hidden;
    margin-bottom: -200px;
    &__title {
        margin-bottom: 16px;
    }
    &__wrapper {
        position: relative;
        width: 100%;
        &.aos-animate {
            .circle-list {
                &__item {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
    &__pic {
        width: 60%;
        z-index: 1;
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1000px;
        height: 1000px;
        margin: 0 auto;
        border-radius: 50%;
        border: 1px solid #A4AEBE;
    }
    &__item {
       position: absolute;
       transform: translateY(100%);
       opacity: 0;
       transition: .3s ease;
       z-index: 2;
       @include set-delay(7);
        &:nth-child(1) {
            top: 45%;
            left: 30px;
        }
        &:nth-child(2) {
            top: 11%;
            left: 14%;
        }
        &:nth-child(3) {
            top: -55px;
            left: 45%;
        }
        &:nth-child(4) {
            top: 11%;
            right: 14%;
        }
        &:nth-child(5) {
            top: 45%;
            right: 30px;
        }
    }
    &__item-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__icon {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        background: #52C1C8;
        padding: 25px;
        box-shadow: 5px 5px 15px rgba(38, 40, 61, 0.1);
        .icon-svg {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            fill: #FFFFFF;
        }
    }
    &__text {
        position: absolute;
        top: calc(100% + 30px);
        left: 50%;
        transform: translateX(-50%);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #22232E;
        text-align: center;
        width: 300px;
    }
}

@media screen and (max-width: 1400px) { 
    .circle-list {
        &__list {
            width: 740px;
            height: 740px;
        }
        &__pic {
            width: 60%;
        }
        &__icon {
            width: 80px;
            height: 80px;
        }
        &__text {
            top: calc(100% + 10px);
            font-weight: 400;
            font-size: 16px;
            line-height: 1.2;
        }
    }
}

@media screen and (max-width: 1023px) { 
    .circle-list {
        padding: 50px 0;
        margin-bottom: 0;
        &__title {
            margin-bottom: 30px;
        }
        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            width: 100%;
            height: auto;
            border: none;
        }
        &__item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            position: static;
            width: 100%;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__icon {
            width: 32px;
            height: 45px;
            background: transparent;
            padding: 0;
            box-shadow: none;
            margin-right: 23px;
            .icon-svg {
                fill: #52C1C8;
            }
        }
        &__text {
            position: static;
            transform: translateX(0);
            text-align: left;
            width: auto;
        }
        &__pic {
            grid-column: 1 / -1;
            width: 100%;
            img {
                max-width: 400px;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 660px) { 
    .circle-list {
        &__item {
            transform: translateY(0);
        }
        &__list {
            grid-template-columns: 1fr;
        }
        &__text {
            font-weight: normal;
            font-size: 18px;
            line-height: 128%;
            color: #6C6E75;
        }
    }
}
.right-pic-bg {
    background: #F0F8FA;
    padding: 50px 0;
    overflow: hidden;
    &__inner {
        position: relative;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-radius: 30px;
        padding: 75px 70px;
    }
    &__content {
        width: 55%;
        max-width: 430px;
    }
    &__pic {
        position: relative;
        flex-grow: 1;
        img {
            position: absolute;
            bottom: -75px;
            right: -150px;
            width: calc(100% + 75px);
            max-width: none;
            height: auto;
        }
        &_tenantlab{
            img{
                width: 100%;
                right: -90px;
                @media(max-width: 1400px){
                    width: calc(100% + 130px)!important;
                    top: 0;
                    right: -150px;
                }
                @media(max-width: 1024px){
                    position: static;
                    width: 60%!important;
                    margin: 50px auto;
                    right: auto;
                    top: auto;
                }
                @media(max-width: 600px){
                    width: 100%!important;
                }
            }
        }
        &_labloyalty{
            img{
                width: calc(100% + 80px);
                bottom: 0;
                right: -120px;
                @media(max-width: 1400px){
                    width: calc(100% + 170px) !important;
                    top: auto;
                    right: -150px;
                    bottom: 30px;
                }
                @media(max-width: 1024px){
                    position: static;
                    width: 60%!important;
                    margin: 0 auto 50px;
                    right: auto;
                    top: auto;
                }
                @media(max-width: 600px){
                    width: 100%!important;
                }
            }
        }
    }
    &__title {
        margin-bottom: 25px;
    }
    &__text {
        ul {
            padding-left: 20px;
            li {
                display: list-item;
                list-style: disc;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .right-pic-bg {
        &__pic {
            img {
                width: calc(100% + 160px);
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .right-pic-bg {
        padding-bottom: 0;
        &__inner {
            flex-direction: column;
            padding: 0;
            background: inherit;
        }
        &__content {
            width: 100%;
            max-width: none;
        }
        &__pic {
            img {
                position: static;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .right-pic-bg {
        overflow: hidden;
        &__pic {
            img {
                width: 489.4px;
                max-width: none;
            }
        }
    }
}

.right-pic-bg{
    &__loyalty_integration{
        .right-pic-bg{
            &__inner{
                padding: 50px 100px 309px;
                @media(max-width: 1400px){
                    padding: 50px 100px 109px;
                }
                @media(max-width: 1024px){
                    padding: 0;
                }
            }
            &__pic{
                img{
                    width: calc(100% + 300px);
                    right: -220px;
                    top: -130px;
                    @media(max-width: 1400px){
                        width: calc(100% + 300px)!important;
                    }
                    @media(max-width: 1024px){
                        width: 100%!important;
                        position: relative;
                        top: -10px;
                        right: auto;
                        margin: 0;
                    }
                }
            }
            &__content{
                max-width: 370px;
                @media(max-width: 1024px){
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
    &-dam-ml{
        .right-pic-bg{
            &__inner{
                padding: 50px 100px 283px;
                @media(max-width: 1024px){
                    padding: 0px 0 0px;
                }
            }
            &__pic{
                position: initial;
                img{
                    width: 100%;
                    max-width: 665px;
                    right: -20px;
                    top: auto;
                    bottom: 0;
                    @media(max-width: 1400px){
                        width: 100%!important;
                        max-width: 600px;
                        right: -130px;
                    }
                    @media(max-width: 1024px){
                        width: 100%!important;
                        position: relative;
                        right: auto;
                        top: auto;
                        margin: 30px auto 0;
                    }
                }
            }
        }
    }
}

.swiper-design {
    position: relative;
    padding: 130px 0 260px;
    background: #F0F8FA;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    &__empty {
        flex: 2 1 0;
    }
    &__content {
        flex: 3 1 0;
        padding-left: 60px;
    }
    &__wrapper {
        position: absolute;
        top: calc(100% - 260px);
        width: 100%;
        padding-left: 21%;
    }
    &__swiper {
        overflow: visible;
    }
    &__slide {
        width: 370px;
        transition-delay: .5s;
        cursor: pointer;
        transition: all .3s ease-out;
        img {
            width: 100%;
            height: auto;
        }
        &.swiper-slide-active {
            transform: translate3d(0, -50%, 0);
            transition-delay: .5s;
        }
        &.swiper-slide-prev {
            opacity: 0;
        }
    }
    &__btns {
        display: flex;
        margin-bottom: 60px;
        align-items: center;
    }
    &__prev {
        background: #FFFFFF;
    }
    &__next {
        background: #FFFFFF;
        .icon-svg {
            transform: scaleX(-1);
        }
    }
    &__title {
        margin-bottom: 30px;
    }
    &__text {
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 26px;
        p {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .swiper-design {
        padding: 50px 0;
        &__wrapper {
            top: calc(100% - 50px);
            padding-left: 95px;
        }
        &__slide {
            &.swiper-slide-active {
                transform: translate3d(0, -95%, 0);
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .swiper-design {
        padding: 55px 0 240px;
        &__empty {
            display: none;
        }
        &__text {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
        }
        &__content {
            padding: 0 16px;
        }
        &__wrapper {
            top: calc(100% - 210px);
            padding-left: 16px;
        }
        &__slide {
            &.swiper-slide-active {
                transform: translate3d(0, -60px, 0);
            }
        }
    }
}

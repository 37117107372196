.vacancies__page-list{
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    .page-list{
        &__col{
            width: calc(50% - 15px);
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            @media(max-width: 768px){
                width: 100%;
            }
        }
    }
}

.about-contacts__btn-hh{
    .intro__form-btn{
        display: block;
        margin-top: 40px;
        text-align: center;
        margin-bottom: 30px;
    }
}

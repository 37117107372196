.animation {
    position: relative;
    padding: 150px 0;
    background: #F0F8FA;
    overflow: hidden;
    &__inner {
        display: flex;
        justify-content: space-between;
    }
    &__frame {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 48%;
        max-width: 840px;
        overflow: hidden;
        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &__frame-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    &__frame-pic {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
        }
    }
    &__frame-content {
        position: absolute;
        top: 8%;
        right: 0;
        width: 86%;
    }
    &__content {
        width: 49%;
        max-width: 570px;
    }
    &__title {
        margin-bottom: 34px;
    }
    &__text {
        p {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__circle {
        fill: url(#radial-gradient);
        transition: .3s ease;
    }
}

@keyframes move {
    0% {
        offset-distance: 0%;
    }
    100% {
        offset-distance: 100%;
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 90%;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes pulse {
    0% {
        stop-color: #FC3C3C;
    }
    50% {
        stop-color: #fc3c3c00;
    }
    100% {
        stop-color: #FC3C3C;
    }
}


.stop-2 {
    animation: pulse 1.5s ease-in-out infinite;
}

#circle-1 {
    animation: move 332s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    transition: .3s ease;
    offset-path: path("M176.5 76.5L34 88H-34L159 69.5L242.5 65H358L509.5 76.5L650 92.5C681.167 95.8333 771 147 769.5 157C768 167 752 255 752 255L733.5 353.5C729.667 366.667 710.1 398.1 662.5 418.5L578.5 430.5L435 441C418 438.667 374.5 420.7 336.5 367.5V255L331.5 157C334.667 134 325.6 85.7 264 76.5H176.5Z");
}

#circle-2 {
    animation: move 325s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    transition: .3s ease;
    offset-path: path("M181.5 429C155.5 428 -31.5 419 -31.5 419V-15H900.5V121C900.5 121 809.5 115 793.5 121C777.5 127 774.7 153.4 763.5 209L739 340.5C737 355.333 718.3 391.8 659.5 419C659.5 419 377.5 440.5 343 438.5C308.5 436.5 207.5 430 181.5 429Z");
}

#circle-3 {
    animation: move 327s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    transition: .3s ease;
    offset-path: path("M702.5 524C702.5 428 655.5 402.667 632 402L454 410C392 403.6 389.166 386.333 395.5 378.5V193C395.5 193 395.5 139.5 395.5 121C395.5 102.5 472 77.4999 492.5 77.5C513 77.5001 664.5 103 664.5 103C736.9 125.4 746.333 154 742 165.5L702.5 378.5L685.5 416H137L-32.5 402V531L702.5 524Z");
}

#circle-4 {
    animation: move 330s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    transition: .3s ease;
    offset-path: path("M752 109H884.5V135.5L744 157.5C744 157.5 732 255 727 275.5C722 296 703.5 381 695.5 395C687.5 409 495.5 409 495.5 409C495.5 409 368.5 414 322 415C275.5 416 242 409 202.5 409C163 409 -39.5 395 -39.5 395V79.5001L37 72.5001C37 72.5001 280.5 66.5001 328 65.5001C375.5 64.5001 511.5 74.0001 550 79.5001C588.5 85.0001 730.5 109 752 109Z");
}

#circle-5 {
    cy: 16%;
    cx: 48%;
}
#circle-6 {
    cy: 14%;
    cx: 50%;
}


@media screen and (max-width: 1024px) { 
    .animation {
        padding: 50px 0;
        &__inner {
            flex-direction: column;
        }
        &__content {
            max-width: none;
            width: 100%;
            margin-bottom: 22px;
        }
        &__frame {
            position: static;
            transform: translateY(0);
            width: calc(100% + 95px);
            max-width: none;
        }
    }
}

@media screen and (max-width: 660px) { 
    .animation {
        &__frame {
            width: calc(100% + 16px);
        }
    }
}